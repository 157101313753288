import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import tileService from "../../services/tileService";

const initialState = {
  //* GET all state
  status: "idle",
  tiles: null,
  isLoading: false,
  error: null,

  //* DELETE single state
  deleteStatus: "idle",
  deleteIsLoading: false,
  deleteError: null,

  //* Update Published single state
  publishedStatus: "idle",
  publishedIsLoading: false,
  publishedError: null,

  //* Update Premium single state
  premiumStatus: "idle",
  premiumIsLoading: false,
  premiumError: null,

  //* Update Price state
  priceStatus: "idle",
  priceIsLoading: false,
  priceError: null,
};

export const deleteTileAsync = createAsyncThunk(
  "adminTiles/deleteTileAsync",
  async (payload, thunkAPI) => {
    try {
      return await tileService.deleteTile(payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateTilePriceAsync = createAsyncThunk(
  "adminTiles/updateTilePriceAsync",
  async (payload, thunkAPI) => {
    try {
      return await tileService.updatePrice(payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updatePremiumTileAsync = createAsyncThunk(
  "adminTiles/updatePremiumTileAsync",
  async (payload, thunkAPI) => {
    try {
      return await tileService.updatePremium(payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updatePublishedTileAsync = createAsyncThunk(
  "adminTiles/updatePublishedTileAsync",
  async (payload, thunkAPI) => {
    try {
      return await tileService.updatePublishing(payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAdminTilesAsync = createAsyncThunk(
  "adminTiles/getAdminTilesAsync",
  async (_, thunkAPI) => {
    try {
      return await tileService.getAllAdminTiles();
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const adminTilesSlice = createSlice({
  name: "adminTiles",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
    },resetPriceStatus: (state) => {
      state.priceStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    //! Get All Existing Tiles
    builder.addCase(getAdminTilesAsync.pending, (state) => {
      state.status = "idle";
      state.isLoading = true;
      state.tiles = null;
      state.error = null;
    });
    builder.addCase(getAdminTilesAsync.fulfilled, (state, action) => {
      state.status = "getAdminTilesAsyncSuccess";
      state.isLoading = false;
      state.tiles = action.payload;
      state.error = null;
    });
    builder.addCase(getAdminTilesAsync.rejected, (state, action) => {
      state.status = "idle";
      state.isLoading = false;
      state.tiles = null;
      state.error = action.payload;
    });
    //! Delete Tile
    builder.addCase(deleteTileAsync.pending, (state) => {
      state.deleteStatus = "idle";
      state.deleteIsLoading = true;
      state.deleteError = null;
    });
    builder.addCase(deleteTileAsync.fulfilled, (state, action) => {
      state.deleteStatus = "deleteTileAsyncSuccess";
      state.deleteIsLoading = false;
      state.deleteError = null;
    });
    builder.addCase(deleteTileAsync.rejected, (state, action) => {
      state.deleteStatus = "idle";
      state.deleteIsLoading = false;
      state.deleteError = action.payload;
    });
    //! Update Tile Published
    builder.addCase(updatePublishedTileAsync.pending, (state) => {
      state.publishedStatus = "idle";
      state.publishedIsLoading = true;
      state.publishedError = null;
    });
    builder.addCase(updatePublishedTileAsync.fulfilled, (state, action) => {
      state.publishedStatus = "updatePublishedTileAsyncSuccess";
      state.publishedIsLoading = false;
      state.publishedError = null;
    });
    builder.addCase(updatePublishedTileAsync.rejected, (state, action) => {
      state.publishedStatus = "idle";
      state.publishedIsLoading = false;
      state.publishedError = action.payload;
    });
    //! Update Premium Status
    builder.addCase(updatePremiumTileAsync.pending, (state) => {
      state.premiumStatus = "idle";
      state.premiumIsLoading = true;
      state.premiumError = null;
    });
    builder.addCase(updatePremiumTileAsync.fulfilled, (state, action) => {
      state.premiumStatus = "updatePremiumTileAsyncSuccess";
      state.premiumIsLoading = false;
      state.premiumError = null;
    });
    builder.addCase(updatePremiumTileAsync.rejected, (state, action) => {
      state.premiumStatus = "idle";
      state.premiumIsLoading = false;
      state.premiumError = action.payload;
    });
    //! Update Price
    builder.addCase(updateTilePriceAsync.pending, (state) => {
      state.priceStatus = "idle";
      state.priceIsLoading = true;
      state.priceError = null;
    });
    builder.addCase(updateTilePriceAsync.fulfilled, (state, action) => {
      state.priceStatus = "updateTilePriceAsyncSuccess";
      state.priceIsLoading = false;
      state.priceError = null;
    });
    builder.addCase(updateTilePriceAsync.rejected, (state, action) => {
      state.priceStatus = "idle";
      state.priceIsLoading = false;
      state.priceError = action.payload;
    });
  },
});

export const adminTilesActions = adminTilesSlice.actions;
export const adminTilesReducer = adminTilesSlice.reducer;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoChevronDown } from "react-icons/io5";

const Dropdown = ({ title, items }) => {
  const [open, setOpen] = useState(false);

  const handleDropdown = () => {
    setOpen((s) => !s);
  };

  return (
    <div className="custom-dropdown">
      <button
        onClick={handleDropdown}
        className="btn btn-dark text-start w-100 px-2 py-3"
      >
        {title} <IoChevronDown className={`dropdown-icon ${open && "open"}`} />
      </button>
      {open ? (
        <div className="dropdown-links pb-2">
          {items.map((item) => (
            <Link
              key={item.name}
              className="pt-2 d-block ps-4 text-decoration-none text-light"
              to={item.path}
            >
              <IoChevronDown className="dropdown-icon sublink" /> {item.name}
            </Link>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Dropdown;

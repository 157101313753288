import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import tileService from "../../services/tileService";

const initialState = {
  status: "idle",
  tile: null,
  isLoading: false,
  error: null,
};

export const getTileAsync = createAsyncThunk(
    "tile/getTileAsync",
    async (payload, thunkAPI) => {
      try {
        return await tileService.getSingleTile(payload);
      } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

export const singleTileSlice = createSlice({
  name: "tile",
  initialState,
  reducers: {
    resetState: (state) => {
      state.isLoading = true;
    },
  },
  extraReducers: (builder) => {
    //! Get Single Tile
    builder.addCase(getTileAsync.pending, (state) => {
      state.status = "idle";
      state.isLoading = true;
      state.tile = null;
      state.error = null;
    });
    builder.addCase(getTileAsync.fulfilled, (state, action) => {
      state.status = "tilesSuccess";
      state.isLoading = false;
      state.tile = action.payload;
      state.error = null;
    });
    builder.addCase(getTileAsync.rejected, (state, action) => {
      state.status = "idle";
      state.isLoading = false;
      state.tile = null;
      state.error = action.payload;
    });
  },
});

export const singleTileActions = singleTileSlice.actions;
export const singleTileReducers = singleTileSlice.reducer;

import React from "react";

const Locations = () => {
  return (
    <div className="custom-container-md pb-5">
      <div className="w-100 px-2 navigation-map-text py-3 custom-border-top custom-border-bottom filters-bar d-flex justify-content-between align-items-center">
        {"Početna > Saloni"}
      </div>
      <div className="mh-100vh-header-map">
        <div className="row g-0">
          <div className="col-md-8">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11673.867837049189!2d20.3355209!3d42.989501!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135327eb3f10a46d%3A0xefe65cf19aa6e035!2sSalon%20Keramike%20AM%20Ceramics!5e0!3m2!1shr!2srs!4v1681930209761!5m2!1shr!2srs"
              width="100%"
              className="mh-100vh-header-google-map custom-border-all"
              // style={{ border: "0" }}
              allowFullScreen=""
              title="retail-location"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="col-md-4 p-5 mh-100vh-header-map locaion-details d-flex justify-content-center align-items-start flex-column">
            <span className="">Lokacija 1</span>
            <h1 className="m-0 py-2 mb-auto">Njegoševa 16, Tutin</h1>
            <p>Broj telefona: 063652222</p>
            <p>Radno vreme: 09:00 - 17:00 radnim danima</p>
          </div>{" "}
          {/* <div className="row g-0 mt-5 flex-md-row-reverse custom-border-top">
            <div className="col-md-8">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5731.603904856979!2d20.340736024558144!3d42.97799707192709!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135327f61a99caeb%3A0xadf5b829117a6c4f!2sTutin!5e1!3m2!1shr!2srs!4v1680652921116!5m2!1shr!2srs"
                width="100%"
                className="mh-100vh-header-google-map custom-border-all"
                // style={{ border: "0" }}
                title="wholesale-location"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="col-md-4 p-5 mh-100vh-header-map locaion-details d-flex justify-content-center align-items-start flex-column">
              <span className="">Lokacija 2</span>
              <h1 className="m-0 py-2 mb-auto">Njegoševa 16, Tutin</h1>
              <p>Broj telefona: 0628264606</p>
              <p>Radno vreme: 09:00 - 17:00 radnim danima</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Locations;

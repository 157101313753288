import React, { useEffect } from "react";
import CategoryAsItem from "../components/CategoryAsItem";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTileCategoriesAsync,
  tileCategoryActions,
} from "../store/slices/tileCategorySlice";

const AdminPanelAllCategories = () => {
  //* Redux State
  const { tileCategories, status, error, isLoading, deleteCategoryStatus } =
    useSelector((s) => s.tileCategory);

  const dispatch = useDispatch();

  useEffect(() => {
    if (deleteCategoryStatus === "deleteCategorySuccess")
      dispatch(tileCategoryActions.resetDeleteCategoryStatus());
    dispatch(getAllTileCategoriesAsync());
  }, [dispatch, deleteCategoryStatus]);

  return (
    <div>
      <h2 className="px-4 px-md-5 custom-bg-gray py-4 custom-border-bottom">
        Sve kategorije
      </h2>
      <div className="all-items-cont px-2 py-3 px-md-5">
        {isLoading
          ? "Učitavanje..."
          : status === "tileCategoriesSuccess"
          ? tileCategories.map((n) => (
              <CategoryAsItem key={n?.id} category={n} />
            ))
          : error && "Greška..."}
      </div>
    </div>
  );
};

export default AdminPanelAllCategories;

import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  adminTilesActions,
  updateTilePriceAsync,
} from "../store/slices/adminTilesSlice";

const AdminPanelEditProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { priceStatus, priceIsLoading } = useSelector((s) => s.adminTiles);

  const [ppsmr, setRetail] = useState(0);
  const [ppsmw, setWholesale] = useState(0);
  const [error, setError] = useState(null);

  const submitHandler = (e) => {
    e.preventDefault();
    if (window.confirm("Da li ste sigurni?")) {
      if (!ppsmr || !ppsmw || ppsmr <= 0 || ppsmw <= 0) {
        setError("Neispravne vrednosti.");
        return;
      }

      dispatch(updateTilePriceAsync({ id, prices: { ppsmr, ppsmw } }));
    }
  };

  const resetHandler = (e) => {
    e.preventDefault();
    setError(null);
    setRetail(0);
    setWholesale(0);
  };

  useEffect(() => {
    if (priceStatus === "updateTilePriceAsyncSuccess") {
      dispatch(adminTilesActions.resetPriceStatus());
      navigate("/admin-panel/products");
    }
  }, [priceStatus]);

  return (
    <div className="">
      <h2 className="px-4 px-md-5 custom-bg-gray m-0 py-4 custom-border-bottom">
        Ažuriranje proizvoda
      </h2>
      <form onSubmit={submitHandler} className="w-100">
        <div className="w-100 row g-0">
          <div className="col-lg-7 ps-4 ps-md-5 p-4 custom-border-bottom">
            <h5>Cene proizvoda</h5>
            <div className="py-3">
              <p>Maloprodajna:</p>
              <input
                onChange={(e) => setRetail(e.target.value)}
                value={ppsmr}
                type="number"
                className="add-product-input-dimension mt-2"
              />
            </div>
            <div className="pb-3">
              <p>Veleprodajna:</p>
              <input
                onChange={(e) => setWholesale(e.target.value)}
                value={ppsmw}
                type="number"
                className="add-product-input-dimension mt-2"
              />
            </div>
          </div>
          <div className="col-lg-5"></div>
          <div className="col-lg-7 mb-5 p-4 px-4 px-md-5 d-flex align-items-center justify-content-start gap-2">
            <button
              disabled={priceIsLoading}
              type="submit"
              className="btn px-5 py-2 btn-success"
            >
              {priceIsLoading ? "Ažuriranje..." : "Potvrdi"}
            </button>
            <button
              disabled={priceIsLoading}
              onClick={resetHandler}
              className="btn px-5 py-2 btn-danger"
            >
              Poništi
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdminPanelEditProduct;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTotalUsersCountAsync } from "../store/slices/usersSlice";

const AdminPanelUserStats = () => {
  const dispatch = useDispatch();

  //* Global state
  const { total, isLoading, totalCountStatus, error } = useSelector(
    (s) => s.users
  );

  useEffect(() => {
    //* Fetching total count
    dispatch(getTotalUsersCountAsync());
  }, [dispatch]);

  return (
    <div>
      <h2 className="px-4 px-md-5 py-4 custom-bg-gray custom-border-bottom">
        Statistika korisnika
      </h2>
      <div className="p-4 p-md-5">
        {isLoading
          ? "Učitavanje..."
          : error
          ? "Greška..."
          : totalCountStatus === "getTotalUsersCountSuccess" && (
              <h5>
                Broj korisnika <b>am-ceramics.rs</b> iznosi: <br></br>
                <br></br>
                <b>{total}</b>
              </h5>
            )}
      </div>
    </div>
  );
};

export default AdminPanelUserStats;

import "./App.css";
import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { authActions } from "./store/slices/authSlice";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "./assets/images/am2title.png";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Products from "./pages/Products";
import RouteHandler from "./components/route/RouteHandler";
import AdminPanel from "./pages/AdminPanel";
import Cart from "./pages/Cart";
import ProductDetails from "./pages/ProductDetails";
import AdminPanelAllProducts from "./pages/AdminPanelAllProducts";
import AdminPanelAddProduct from "./pages/AdminPanelAddProduct";
import AdminPanelUserStats from "./pages/AdminPanelUserStats";
import Contact from "./pages/Contact";
import Locations from "./pages/Locations";
import AdminPanelAllCategories from "./pages/AdminPanelAllCategories";
import AdminPanelAddCategory from "./pages/AdminPanelAddCategory";
import AdminPanelEditProduct from "./pages/AdminPanelEditProduct";
import NotFound from "./pages/NotFound";
import ConnectionError from "./pages/ConnectionError";

function App() {
  const [initLoading, setInit] = useState(() => {
    //* Get the initial value from localStorage
    const storedValue = localStorage.getItem("initLoading");
    return storedValue ? JSON.parse(storedValue) : true;
  });

  useEffect(() => {
    setTimeout(() => {
      setInit(false); //* Set isLoading to false after a delay
      localStorage.setItem("initLoading", JSON.stringify(false)); //* Store false in localStorage
    }, 3000);
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("user") !== null) {
      const user = JSON.parse(localStorage.getItem("user"));
      axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
      dispatch(authActions.setUser(user));
    }
  }, [dispatch]);

  return initLoading ? (
    <div className="loader-container">
      <div className="init-loader">
        <img src={logo} alt="AM" />
      </div>
    </div>
  ) : (
    <>
      <RouteHandler>
        <Routes>
          {/* //! User routes */}
          <Route path="/" element={<Navigate replace to="/home" />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/locations" element={<Locations />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/:id" element={<ProductDetails />} />
          <Route path="/cart" element={<Cart />} />
          {/* //! Admin routes */}
          <Route path="/admin-panel" element={<AdminPanel />}>
            <Route path="" element={<Navigate replace to="products" />} />
            <Route path="products" element={<AdminPanelAllProducts />} />
            <Route path="add-product" element={<AdminPanelAddProduct />} />
            <Route
              path="edit-product/:id"
              element={<AdminPanelEditProduct />}
            />
            <Route path="user-stats" element={<AdminPanelUserStats />} />
            <Route path="categories" element={<AdminPanelAllCategories />} />
            <Route path="add-category" element={<AdminPanelAddCategory />} />
          </Route>
          {/* //! Error routes */}
          <Route path="/connection-error" element={<ConnectionError />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </RouteHandler>
    </>
  );
}

export default App;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import usersService from "../../services/usersService";

const initialState = {
  //! Total Users Count state
  totalCountStatus: "idle",
  total: null,
  isLoading: false,
  error: null,

  //! Other state ...
};

export const getTotalUsersCountAsync = createAsyncThunk(
  "users/getTotalUsersCountAsync",
  async (payload, thunkAPI) => {
    try {
      return await usersService.getTotalUsersCount(payload);
    } catch (e) {
      return thunkAPI.rejectWithValue({ error: "Greška..." });
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.totalCountStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    // Get Count
    builder.addCase(getTotalUsersCountAsync.pending, (state) => {
      state.totalCountStatus = "idle";
      state.isLoading = true;
      state.total = null;
      state.error = null;
    });
    builder.addCase(getTotalUsersCountAsync.fulfilled, (state, action) => {
      state.totalCountStatus = "getTotalUsersCountSuccess";
      state.isLoading = false;
      state.total = action.payload;
      state.error = null;
    });
    builder.addCase(getTotalUsersCountAsync.rejected, (state, action) => {
      state.totalCountStatus = "idle";
      state.isLoading = false;
      state.total = null;
      state.error = action.payload.error;
    });
  },
});

export const usersActions = usersSlice.actions;
export const usersReducers = usersSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../../services/authService";

const initialState = {
  status: "idle",
  user: null,
  isLoading: false,
  error: null,
  registerError: null,
  isLoadingResetPassword: false,
  resetPasswordError: null,
  resetPasswordStatus: "idle",
};

export const loginAsync = createAsyncThunk(
  "auth/loginAsync",
  async (payload, thunkAPI) => {
    try {
      //TODO - Change this because it deletes the initalLoading for the animation from localStorage
      localStorage.clear();
      return await authService.login(payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const registerAsync = createAsyncThunk(
  "auth/registerAsync",
  async (payload, thunkAPI) => {
    try {
      return await authService.register(payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const resetPasswordAsync = createAsyncThunk(
  "auth/resetPasswordAsync",
  async (payload, thunkAPI) => {
    try {
      //TODO - Change this because it deletes the initalLoading for the animation from localStorage
      return await authService.resetPassword(payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
    },
    resetResetPasswordStatus: (state) => {
      state.resetPasswordStatus = "idle";
    },
    resetErrors: (state) => {
      state.error = null;
      state.registerError = null;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.status = "idle";
      state.isLoading = false;
      state.user = null;
      state.error = null;
      state.registerError = null;

      //TODO - Change this because it deletes the initalLoading for the animation from localStorage
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    // Login
    builder.addCase(loginAsync.pending, (state) => {
      state.status = "idle";
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(loginAsync.fulfilled, (state, action) => {
      state.status = "authSuccess";
      state.isLoading = false;
      state.user = action.payload;
      state.error = null;
    });
    builder.addCase(loginAsync.rejected, (state, action) => {
      state.status = "idle";
      state.isLoading = false;
      state.error = action.payload;
    });

    // Register
    builder.addCase(registerAsync.pending, (state) => {
      state.status = "idle";
      state.isLoading = true;
      state.registerError = null;
    });
    builder.addCase(registerAsync.fulfilled, (state, action) => {
      state.status = "authSuccess";
      state.isLoading = false;
      state.user = action.payload;
      state.registerError = null;
    });
    builder.addCase(registerAsync.rejected, (state, action) => {
      state.status = "idle";
      state.isLoading = false;
      state.registerError = action.payload;
    });

    // ResetPassword
    builder.addCase(resetPasswordAsync.pending, (state) => {
      state.resetPasswordStatus = "resetPasswordLoading";
      state.isLoadingResetPassword = true;
      state.resetPasswordError = null;
    });
    builder.addCase(resetPasswordAsync.fulfilled, (state) => {
      state.resetPasswordStatus = "resetPasswordSuccess";
      state.isLoadingResetPassword = false;
      state.resetPasswordError = null;
    });
    builder.addCase(resetPasswordAsync.rejected, (state, action) => {
      state.resetPasswordStatus = "resetPasswordError";
      state.isLoadingResetPassword = false;
      state.resetPasswordError = action.payload;
    });
  },
});

export const authActions = authSlice.actions;
export const authReducers = authSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import tileService from "../../services/tileService";

const initialState = {
  status: "idle",
  tiles: null,
  tilesCount: 0,
  isLoading: false,
  error: null,
};

export const getAllTilesAsync = createAsyncThunk(
    "tiles/getAllTilesAsync",
    async (payload, thunkAPI) => {
      try {
        return await tileService.getAllTiles(payload);
      } catch (e) {
        return thunkAPI.rejectWithValue({ error: e.response.data.Message });
      }
    }
  );

export const tileSlice = createSlice({
  name: "tiles",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    // Get All
    builder.addCase(getAllTilesAsync.pending, (state) => {
      state.status = "idle";
      state.isLoading = true;
      state.tiles = null;
      state.tilesCount = 0;
      state.error = null;
    });
    builder.addCase(getAllTilesAsync.fulfilled, (state, action) => {
      state.status = "tilesSuccess";
      state.isLoading = false;
      state.tiles = action.payload.tiles;
      state.tilesCount = action.payload.count;
      state.error = null;
    });
    builder.addCase(getAllTilesAsync.rejected, (state, action) => {
      state.status = "idle";
      state.isLoading = false;
      state.tiles = null;
      state.tilesCount = 0;
      state.error = action.payload.error;
    });
  },
});

export const tileActions = tileSlice.actions;
export const tileReducers = tileSlice.reducer;

import React, { useEffect } from "react";
import ProductAsItem from "../components/ProductAsItem";
import { useDispatch, useSelector } from "react-redux";
import { getAdminTilesAsync } from "../store/slices/adminTilesSlice";

const AdminPanelAllProducts = () => {
  const dispatch = useDispatch();

  //* Global state
  const { isLoading, tiles, deleteStatus, publishedStatus, premiumStatus } =
    useSelector((s) => s.adminTiles);

  useEffect(() => {
    dispatch(getAdminTilesAsync());
  }, [dispatch, deleteStatus, publishedStatus, premiumStatus]);

  return (
    <div>
      <h2 className="px-4 px-md-5 custom-bg-gray py-4 custom-border-bottom">
        Svi proizvodi
      </h2>
      {/* <Pagination
        total={120}
        current={1}
        nextFn={paginationNextHandler}
        prevFn={paginationPrevHandler}
        itemsPerPage={16}
      /> */}
      <div className="all-items-cont px-2 pt-5 px-md-5">
        {isLoading
          ? "Ucitavanje..."
          : tiles?.map((n) => <ProductAsItem key={n?.id} product={n} />)}
      </div>
      {/* <Pagination
        total={120}
        current={1}
        nextFn={paginationNextHandler}
        prevFn={paginationPrevHandler}
        itemsPerPage={16}
      /> */}
      <div className="pb-5"></div>
    </div>
  );
};

export default AdminPanelAllProducts;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import tileCategoryService from "../../services/tileCategoryService";

const initialState = {
  //* Fetch categories state
  status: "idle",
  tileCategories: null,
  isLoading: false,
  error: null,

  //* Add category state
  createCategoryStatus: "idle",
  createCategoryIsLoading: false,
  createCategoryError: null,

  //* Delete category state
  deleteCategoryStatus: "idle",
  deleteCategoryIsLoading: false,
  deleteCategoryError: null,
};

export const getAllTileCategoriesAsync = createAsyncThunk(
  "category/getAllTileCategoriesAsync",
  async (_, thunkAPI) => {
    try {
      return await tileCategoryService.getAllTileCategories();
    } catch (e) {
      return thunkAPI.rejectWithValue({ error: "Error" });
    }
  }
);

export const createTileCategoryAsync = createAsyncThunk(
  "category/createTileCategoryAsync",
  async (payload, thunkAPI) => {
    try {
      return await tileCategoryService.createCategory(payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteTileCategoryAsync = createAsyncThunk(
  "category/deleteTileCategoryAsync",
  async (payload, thunkAPI) => {
    try {
      return await tileCategoryService.deleteCategory(payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const tileCategorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
    },
    resetIsChecked: (state) => {
      state.tileCategories = state.tileCategories.map((tileCategory) => ({
        ...tileCategory,
        isChecked: false,
      }));
    },
    changeChecked: (state, action) => {
      if (state.tileCategories)
        for (let i = 0; i < state.tileCategories.length; i++) {
          if (action.payload.includes(state.tileCategories[i].id)) {
            state.tileCategories[i].isChecked =
              !state.tileCategories[i].isChecked;
          }
        }
    },
    setCategoriesChecked: (state, action) => {
      const newCategoriesArr = [...state.tileCategories];
      for (let i = 0; i < newCategoriesArr.length; i++) {
        if (action.payload.includes(newCategoriesArr[i].id)) {
          newCategoriesArr[i].isChecked = true;
        }
      }

      state.tileCategories = [...newCategoriesArr];
    },
    resetCreateCategoryStatus: (state) => {
      state.createCategoryStatus = "idle";
    },
    resetDeleteCategoryStatus: (state) => {
      state.deleteCategoryStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    //! Get All
    builder.addCase(getAllTileCategoriesAsync.pending, (state) => {
      state.status = "idle";
      state.isLoading = true;
      state.tileCategories = null;
      state.error = null;
    });
    builder.addCase(getAllTileCategoriesAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.tileCategories = action.payload.map((tc) => ({
        ...tc,
        isChecked: false,
      }));
      state.status = "tileCategoriesSuccess";
      state.error = null;
    });
    builder.addCase(getAllTileCategoriesAsync.rejected, (state, action) => {
      state.status = "idle";
      state.isLoading = false;
      state.tileCategories = null;
      state.error = action.payload;
    });
    //! Create Category
    builder.addCase(createTileCategoryAsync.pending, (state) => {
      state.createCategoryStatus = "idle";
      state.createCategoryIsLoading = true;
      state.createCategoryError = null;
    });
    builder.addCase(createTileCategoryAsync.fulfilled, (state, action) => {
      state.createCategoryStatus = "createTileCategorySuccess";
      state.createCategoryIsLoading = false;
      state.createCategoryError = null;
    });
    builder.addCase(createTileCategoryAsync.rejected, (state, action) => {
      state.createCategoryStatus = "idle";
      state.createCategoryIsLoading = false;
      state.createCategoryError = action.payload;
    });
    //! Create Category
    builder.addCase(deleteTileCategoryAsync.pending, (state) => {
      state.deleteCategoryStatus = "idle";
      state.deleteCategoryIsLoading = true;
      state.deleteCategoryError = null;
    });
    builder.addCase(deleteTileCategoryAsync.fulfilled, (state, action) => {
      state.deleteCategoryStatus = "deleteTileCategorySuccess";
      state.deleteCategoryIsLoading = false;
      state.deleteCategoryError = null;
    });
    builder.addCase(deleteTileCategoryAsync.rejected, (state, action) => {
      state.deleteCategoryStatus = "idle";
      state.deleteCategoryIsLoading = false;
      state.deleteCategoryError = action.payload;
    });
  },
});

export const tileCategoryActions = tileCategorySlice.actions;
export const tileCategoryReducers = tileCategorySlice.reducer;

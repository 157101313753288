import { API_URL } from "../config/apiUrl";
import CustomAxiosInsance from "../middleware/axios/connectionErrorMiddleware";

const api = CustomAxiosInsance();
api.defaults.baseURL = API_URL;

const methods = {
  confirmCart: (payload) =>
    api.post("cart", payload).then((response) => response.data),
};

export default methods;

import React from "react";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../config/apiUrl";
import noPic from "../assets/images/noPic.png";

const ProductCard = ({
  productId,
  wholeSalePrice,
  retailPrice,
  imageSrc,
  tileName,
  categoryName,
  width,
  length,
  height,
  premium,
}) => {
  return (
    <div
      className={`custom-border-all col-xl-3 col-md-4 col-sm-6 p-4 tile-card ${
        premium && "tile-card-premium"
      } mb-3`}
    >
      {/* Image src is going to be changed after image storage is implemented */}
      <Link to={`/products/${productId}`}>
        <img
          alt="tile-cover"
          className="card-image"
          src={imageSrc ? IMAGE_URL + imageSrc : noPic}
        />
      </Link>
      <div className="tile-card-content px-3 custom-border-left-dark mt-3 d-flex flex-column">
        <span className="tile-title">
          {tileName} <span className="tile-category">{categoryName}</span>
        </span>
        <span className="tile-dimensions text-lowercase">
          {width}cm x {length}cm x {height - 1}mm
        </span>
        {/* <span className="tile-price py-3">
          <span>
            Maloprodajna cena: <br></br>
            {retailPrice}
          </span>{" "}
          RSD + PDV
        </span>
        <span className="tile-price">
          <span>
            Veleprodajna cena: <br></br>
            {wholeSalePrice}
          </span>{" "}
          RSD + PDV
        </span> */}
      </div>
    </div>
  );
};

export default ProductCard;

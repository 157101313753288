import React, { useEffect, useState } from "react";
import { IoFunnelOutline } from "react-icons/io5";
import ProductFilters from "../components/ProductFilters";
import { useDispatch, useSelector } from "react-redux";
import { getAllTilesAsync } from "../store/slices/tileSlice";
import { getAllTileCategoriesAsync } from "../store/slices/tileCategorySlice";
import { motion } from "framer-motion";
import Pagination from "../components/shared/Pagination";
import ProductCard from "../components/ProductCard";
import Newsletter from "../components/Newsletter";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const Products = () => {
  const [filtersShow, setFiltersShow] = useState(false);
  const dispatch = useDispatch();
  const { tiles, tilesCount, isLoading, error } = useSelector(
    (state) => state.tile
  );
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(getAllTileCategoriesAsync());
    dispatch(getAllTilesAsync({}));
  }, [dispatch, pathname]);

  useEffect(() => {
    callDispatch();
  }, [searchParams, dispatch]);

  const callDispatch = () => {
    const minPpsmw = searchParams.get("minPpsmw");
    const maxPpsmw = searchParams.get("maxPpsmw");
    const minPpsmr = searchParams.get("minPpsmr");
    const maxPpsmr = searchParams.get("maxPpsmr");
    const minLength = searchParams.get("minLength");
    const maxLength = searchParams.get("maxLength");
    const minWidth = searchParams.get("minWidth");
    const maxWidth = searchParams.get("maxWidth");
    const minHeight = searchParams.get("minHeight");
    const maxHeight = searchParams.get("maxHeight");
    const colors = searchParams.getAll("colors");
    const categories = searchParams.getAll("categories");
    const pageNumber = searchParams.get("pageNumber");

    if (
      minPpsmw ||
      maxPpsmw ||
      minPpsmr ||
      maxPpsmr ||
      minLength ||
      maxLength ||
      minWidth ||
      maxWidth ||
      minHeight ||
      maxHeight ||
      colors.length > 0 ||
      categories.length > 0 ||
      pageNumber
    )
      dispatch(
        getAllTilesAsync({
          ppsmw: { min: minPpsmw, max: maxPpsmw },
          ppsmr: { min: minPpsmr, max: maxPpsmr },
          length: { min: minLength, max: maxLength },
          width: { min: minWidth, max: maxWidth },
          height: { min: minHeight, max: maxWidth },
          colors,
          selectedCategories: categories,
          pageNumber,
        })
      );
  };

  const openFilters = () => {
    document.body.style.overflow = "hidden";
    setFiltersShow(true);
  };

  const closeFilters = () => {
    document.body.style.overflow = "visible";
    setFiltersShow(false);
  };

  const paginationPrevHandler = () => {
    let oldPageNumber = searchParams.get("pageNumber");
    const queryParams = new URLSearchParams(window.location.search);
    if (oldPageNumber === null) return;
    else {
      if (+oldPageNumber === 1) return;
      queryParams.set("pageNumber", `${Number(oldPageNumber) - 1}`);
      navigate(`?${queryParams.toString()}`);
    }

    // callDispatch()
  };

  const paginationNextHandler = () => {
    let oldPageNumber = searchParams.get("pageNumber");
    const queryParams = new URLSearchParams(window.location.search);
    if (oldPageNumber === null)
      navigate(`?${searchParams.toString()}&pageNumber=${2}`);
    else {
      queryParams.set("pageNumber", `${Number(oldPageNumber) + 1}`);
      navigate(`?${queryParams.toString()}`);
    }

    // callDispatch()
  };

  return (
    <div className="custom-container-md">
      <ProductFilters isOpen={filtersShow} onClose={closeFilters} />
      <div className="w-100 px-2 navigation-map-text py-3 custom-border-top custom-border-bottom filters-bar d-flex justify-content-between align-items-center">
        {"Početna > Proizvodi"}
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="row g-0 mb-5"
      >
        <motion.div
          initial={{ x: -200 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.5 }}
          className="col-lg-6 px-5 custom-bg-gray products-hero-left"
        >
          <h1>Prodaja pločica</h1>
          <p>
            Ne propustite prodaju pločica koja nudi pločice vrhunskog
            kvaliteta samo u ograničenom periodu.
          </p>
        </motion.div>
        <motion.div
          initial={{ x: 200 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.5 }}
          className="col-lg-6 products-hero-right"
        ></motion.div>
      </motion.div>
      <div className="w-100 px-5 px-lg-5 py-4 custom-border-top custom-border-bottom mb-3 filters-bar d-flex justify-content-between align-items-center">
        <button
          onClick={openFilters}
          className="d-flex open-filters-btn justify-content-center align-items-center "
        >
          <IoFunnelOutline />
          <span className="ms-2">Filteri</span>
        </button>
      </div>
      <Pagination
        total={tilesCount}
        current={
          searchParams.get("pageNumber") ? searchParams.get("pageNumber") : 1
        }
        nextFn={paginationNextHandler}
        prevFn={paginationPrevHandler}
        itemsPerPage={16}
      />
      <div className="row g-0 pt-4 mb-3 px-3 px-sm-2 px-lg-0">
        {isLoading || error ? (
          <div className="py-3 d-flex justify-content-center align-items-center">
            <h4 className="text-center">Učitavanje...</h4>
          </div>
        ) : tiles && tiles.length > 0 ? (
          tiles.map((tile) => (
            <ProductCard
              key={tile.id}
              productId={tile.id}
              imageSrc={tile.mainImageUrl}
              tileName={tile.name}
              categoryName={tile.category.name}
              height={tile.height}
              length={tile.length}
              width={tile.width}
              retailPrice={tile.ppsmr}
              wholeSalePrice={tile.ppsmw}
              premium={tile.isPremium}
            />
          ))
        ) : (
          <p className="text-center w-100">Trenutno nema dostupnih oglasa.</p>
        )}
      </div>
      <Pagination
        total={tilesCount}
        current={
          searchParams.get("pageNumber") ? searchParams.get("pageNumber") : 1
        }
        nextFn={paginationNextHandler}
        prevFn={paginationPrevHandler}
        itemsPerPage={16}
      />
      <div className="mt-5"></div>
      <Newsletter />
    </div>
  );
};

export default Products;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import tileService from "../../services/tileService";

const initialState = {
  status: "idle",
  isLoading: false,
  error: null,
};

export const createTileAsync = createAsyncThunk(
  "createTile/createTileAsync",
  async (payload, thunkAPI) => {
    try {
      return await tileService.postTile(payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const createTileSlice = createSlice({
  name: "createTile",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    //! Create Tile
    builder.addCase(createTileAsync.pending, (state) => {
      state.status = "idle";
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createTileAsync.fulfilled, (state, action) => {
      state.status = "createTileAsyncSuccess";
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(createTileAsync.rejected, (state, action) => {
      state.status = "idle";
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const createTileActions = createTileSlice.actions;
export const createTileReducer = createTileSlice.reducer;

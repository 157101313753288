import React, { useEffect } from "react";
import ProductCard from "./ProductCard";
import { Link } from "react-router-dom";
import { getAllSimilarColorTilesAsync } from "../store/slices/sameColorTilesSlice";
import { useDispatch, useSelector } from "react-redux";

const RelatedColorProducts = ({ color, mainTileId }) => {
  const dispatch = useDispatch();
  const { isLoading, tiles, tilesCount, error } = useSelector(
    (s) => s.sameColorTiles
  );

  useEffect(() => {
    dispatch(
      getAllSimilarColorTilesAsync({
        filter: {
          pageSize: 4,
          colors: [color],
          pageNumber: 1,
        },
        mainTileId,
      })
    );
  }, [dispatch]);

  return isLoading || error ? (
    <div className="row g-0 custom-border-top py-5 px-3 px-lg-0">
      <h4 className="text-center">Učitavanje...</h4>
    </div>
  ) : tilesCount - 1 <= 0 ? (
    <h4 className="py-5 custom-border-top px-3 px-lg-0">
      Nema dodatnih proizvoda iste boje.
    </h4>
  ) : (
    <div className="row g-0 custom-border-top py-5 px-3 px-lg-0">
      <h2 className="pb-5">Pločice sličnih boja:</h2>
      {tiles &&
        tiles.map((n) => (
          <ProductCard
            key={"color" + n.id}
            productId={n.id}
            imageSrc={n.mainImageUrl}
            tileName={n.name}
            categoryName={n.category?.name}
            height={n.height}
            length={n.length}
            width={n.width}
            wholeSalePrice={n.ppsmw}
            retailPrice={n.ppsmr}
            premium={n.isPremium}
          />
        ))}
      <div className="col-md-12 pt-5 d-flex justify-content-center align-items-end flex-column">
        {/* {tilesCount - 1} proizvod{tilesCount - 1 !== 1 && "a"} iste boje na
        stanju{" "} */}
        {tilesCount > 4 && (
          <Link
            className="text-dark d-block"
            to={`/products?colors=${color.toLowerCase()}`}
          >
            Pogledaj sve
          </Link>
        )}
      </div>
    </div>
  );
};

export default RelatedColorProducts;

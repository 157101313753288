import React, { useEffect, useState } from "react";
import { IoStar } from "react-icons/io5";
import noPic from "../assets/images/noPic.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllPremiumTilesAsync } from "../store/slices/premiumTilesSlice";
import { IMAGE_URL } from "../config/apiUrl";

const PremiumTiles = () => {
  const dispatch = useDispatch();

  //* Global state
  const { premiumTiles, isLoading, status, error } = useSelector(
    (s) => s.premiumTiles
  );

  //* Component state
  const [slide, setSlide] = useState(0);

  const handleOptionChange = (value) => {
    setSlide(value);
  };

  useEffect(() => {
    //* Fetch all premium products
    dispatch(getAllPremiumTilesAsync());
  }, [dispatch]);

  useEffect(() => {
    //* Auto slider update
    const intervalId = setInterval(() => {
      if (slide < 3) setSlide((s) => s + 1);
      else setSlide(0);
    }, 3750);

    return () => {
      clearInterval(intervalId);
    };
  }, [slide]);

  return (
    <div className="custom-container-md">
      {isLoading ? (
        <div className="h-100vh-header d-flex justify-content-center align-items-center">
          <h4 className="text-center px-3">Učitavanje...</h4>
        </div>
      ) : error ? (
        <div className="my-5 py-5 text-danger d-flex justify-content-center align-items-center">
          <h4 className="text-center px-3">Greška.</h4>
        </div>
      ) : premiumTiles?.length < 4 ? (
        <div className="py-5 my-5 text-dark d-flex justify-content-center align-items-center">
          <h4 className="text-center px-3">Trenutno nema premium oglasa.</h4>
        </div>
      ) : (
        status === "getPremiumTilesSuccess" && (
          <div className="row g-0">
            {/* Left (top) slider */}
            <div className="col-lg-5 slider forward h-100vh-header text-light">
              <div className={`slide-track active-${slide}`}>
                {premiumTiles &&
                  premiumTiles?.map((n, index) => (
                    <div key={n?.id} className="slide">
                      <img
                        src={
                          n?.mainImageUrl ? IMAGE_URL + n?.mainImageUrl : noPic
                        }
                        alt="Slide"
                      />
                      <div className="premium-badge">
                        Premium <IoStar />
                      </div>
                      <div className="slide-overlay h-100 w-100 d-flex flex-column justify-content-center align-items-center">
                        <h1>
                          {n?.name} {n?.category?.name}
                        </h1>
                        <h4 className="pt-2">
                          {n?.width}cm X {n?.length}cm X {n?.height - 1}mm
                        </h4>
                        <Link
                          to={`/products/${n?.id}`}
                          className="mt-3 btn btn-light"
                        >
                          Detaljnije
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            {/* Slider options */}
            <div className="home-hero-middle col-lg-1 h-100vh-header">
              {premiumTiles &&
                premiumTiles?.map((n, index) => (
                  <div
                    key={
                      n?.id + "main"
                    }
                    className={`slider-item-container ${
                      slide === index && "active"
                    }`}
                    onClick={() => handleOptionChange(index)}
                  >
                    <img
                      src={
                        n?.mainImageUrl ? IMAGE_URL + n?.mainImageUrl : noPic
                      }
                      alt="Slider Option"
                    />
                  </div>
                ))}
            </div>
            {/* Right (bottom) slider */}
            <div className="col-lg-6 slider backwards h-100vh-header text-light">
              <div className={`slide-track active-${slide}`}>
                {premiumTiles &&
                  [...premiumTiles].reverse().map((n, index) => (
                    <div
                      className="slide"
                      key={
                        n?.id + "cover"
                      }
                    >
                      <img
                        src={
                          n?.coverImageUrl
                            ? IMAGE_URL + n?.coverImageUrl
                            : noPic
                        }
                        alt="Slide"
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default PremiumTiles;

import { configureStore } from "@reduxjs/toolkit";
import { authReducers } from "./slices/authSlice";
import { tileReducers } from "./slices/tileSlice";
import { tileCategoryReducers } from "./slices/tileCategorySlice";
import { cartReducers } from "./slices/cartSlice";
import { premiumTileReducers } from "./slices/premiumTilesSlice";
import { usersReducers } from "./slices/usersSlice";
import { newsletterReducers } from "./slices/newsletterSlice";
import { contactReducers } from "./slices/contactSlice";
import { singleTileReducers } from "./slices/singleTileSlice";
import { sameColorTilesReducer } from "./slices/sameColorTilesSlice";
import { sameCategoryTilesReducer } from "./slices/sameCategoryTilesSlice";
import { combinationsReducers } from "./slices/combinationsSlice";
import { adminTilesReducer } from "./slices/adminTilesSlice";
import { createTileReducer } from "./slices/createTileSlice";

export const store = configureStore({
  reducer: {
    auth: authReducers,
    tile: tileReducers,
    tileCategory: tileCategoryReducers,
    cart: cartReducers,
    premiumTiles: premiumTileReducers,
    users: usersReducers,
    newsletter: newsletterReducers,
    contact: contactReducers,
    singleTile: singleTileReducers,
    sameColorTiles: sameColorTilesReducer,
    sameCategoryTiles: sameCategoryTilesReducer,
    combinations: combinationsReducers,
    adminTiles: adminTilesReducer,
    createTile: createTileReducer,
  },
});

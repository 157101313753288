import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ linkToNavigate }) => {
  return localStorage.getItem("user") ? (
    <Outlet />
  ) : (
    <Navigate to={linkToNavigate} />
  );
};

export default ProtectedRoute;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import tileService from "../../services/tileService";

const initialState = {
  status: "idle",
  tiles: null,
  isLoading: false,
  error: null,
};

export const getCombinationsAsync = createAsyncThunk(
  "combinations/getCombinationsAsync",
  async (payload, thunkAPI) => {
    try {
      return await tileService.getCombinations(payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const combinationsSlice = createSlice({
  name: "combinations",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    // Get All
    builder.addCase(getCombinationsAsync.pending, (state) => {
      state.status = "idle";
      state.isLoading = true;
      state.tiles = null;
      state.error = null;
    });
    builder.addCase(getCombinationsAsync.fulfilled, (state, action) => {
      state.status = "combinationsSuccess";
      state.isLoading = false;
      state.tiles = action.payload;
      state.error = null;
    });
    builder.addCase(getCombinationsAsync.rejected, (state, action) => {
      state.status = "idle";
      state.isLoading = false;
      state.tiles = null;
      state.error = action.payload.error;
    });
  },
});

export const combinationsActions = combinationsSlice.actions;
export const combinationsReducers = combinationsSlice.reducer;

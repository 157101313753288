import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import tileService from "../../services/tileService";

const initialState = {
  status: "idle",
  tiles: null,
  tilesCount: 0,
  isLoading: false,
  error: null,
};

export const getAllSimilarColorTilesAsync = createAsyncThunk(
  "sameColorTiles/getAllSimilarColorTilesAsync",
  async (payload, thunkAPI) => {
    try {
      var result = await tileService.getAllTiles(payload.filter);
      var newTiles = result.tiles.filter((n) => n.id !== payload.mainTileId);
      return { tiles: newTiles.slice(0, 4), count: result.count };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const sameColorTilesSlice = createSlice({
  name: "sameColorTiles",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    //! Get Same Color Tiles
    builder.addCase(getAllSimilarColorTilesAsync.pending, (state) => {
      state.status = "idle";
      state.isLoading = true;
      state.tiles = null;
      state.tilesCount = 0;
      state.error = null;
    });
    builder.addCase(getAllSimilarColorTilesAsync.fulfilled, (state, action) => {
      state.status = "getAllSimilarColorTilesAsyncSuccess";
      state.isLoading = false;
      state.tiles = action.payload.tiles;
      state.tilesCount = action.payload.count;
      state.error = null;
    });
    builder.addCase(getAllSimilarColorTilesAsync.rejected, (state, action) => {
      state.status = "idle";
      state.isLoading = false;
      state.tiles = null;
      state.tilesCount = 0;
      state.error = action.payload;
    });
  },
});

export const sameColorTilesActions = sameColorTilesSlice.actions;
export const sameColorTilesReducer = sameColorTilesSlice.reducer;

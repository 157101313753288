import React, { useEffect } from "react";
import ProductCard from "./ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { getCombinationsAsync } from "../store/slices/combinationsSlice";

const CombinationProducts = ({ id }) => {
  const dispatch = useDispatch();
  const { isLoading, tiles, status, error } = useSelector(
    (s) => s.combinations
  );

  useEffect(() => {
    dispatch(getCombinationsAsync(id));
  }, [dispatch]);

  return isLoading || error ? (
    <div className="row g-0 custom-border-top py-5 px-3 px-lg-0">
      <h4 className="text-center">Učitavanje...</h4>
    </div>
  ) : tiles?.length > 0 ? (
    <div className="row g-0 custom-border-top py-5 px-3 px-lg-0">
      <h2 className="pb-5">Odgovarajuće kombinacije:</h2>
      {tiles &&
        tiles
          .slice(0, 4)
          .map((n) => (
            <ProductCard
              key={"combo" + n.id}
              productId={n.id}
              imageSrc={n.mainImageUrl}
              tileName={n.name}
              categoryName={n.category?.name}
              height={n.height}
              length={n.length}
              width={n.width}
              wholeSalePrice={n.ppsmw}
              retailPrice={n.ppsmr}
              premium={n.isPremium}
            />
          ))}
    </div>
  ) : (
    <h4 className="py-5 custom-border-top px-3 px-lg-0">
      Odgovarajuće kombinacije nisu navedene.
    </h4>
  );
};

export default CombinationProducts;

import React from "react";

const ConnectionError = () => {
  return (
    <div className="py-5 px-3 my-5 px-md-5">
      <h1 className="text-danger py-5 my-5">Greška pri povezivanju!</h1>
    </div>
  );
};

export default ConnectionError;

import React, { useEffect, useState } from "react";
import noPic from "../assets/images/noPic.png";
import { useNavigate, useParams } from "react-router-dom";
import ProductGalery from "../components/ProductGalery";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../store/slices/cartSlice";
import RelatedColorProducts from "../components/RelatedColorProducts";
import RelatedCategoryProducts from "../components/RelatedCategoryProducts";
import Newsletter from "../components/Newsletter";
import authUser from "../store/selectors/auth/authUser";
import {
  getTileAsync,
  singleTileActions,
} from "../store/slices/singleTileSlice";
import CombinationProducts from "../components/CombinationProducts";
import { IMAGE_URL } from "../config/apiUrl";

const ProductDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  //* Component state
  const [amount, setAmount] = useState(1);
  const [showInSpace, setShowInSpace] = useState(false);
  const [galeryShow, setGaleryShow] = useState(false);
  const [currentImg, setCurrentImg] = useState("");

  //* Global state
  const user = useSelector(authUser);
  const {
    tile: singleTile,
    isLoading,
    error,
    status,
  } = useSelector((s) => s.singleTile);

  const handleAmountChange = (e) => {
    // if (e.target.value > 0) {
    setAmount(e.target.value);
    // }
  };

  const openGalery = (img) => {
    setCurrentImg(img);
    document.body.style.overflow = "hidden";
    setGaleryShow(true);
  };

  const closeGalery = () => {
    document.body.style.overflow = "visible";
    setGaleryShow(false);
    setCurrentImg("");
  };

  const addToCartHandler = () => {
    if (user) {
      const product = {
        id,
        imageSrc: singleTile?.mainImageUrl,
        name: singleTile?.name + " " + singleTile?.category?.name, // {name} {category}
        wholeSalePrice: singleTile?.ppsmw,
        retailPrice: singleTile?.ppsmr,
        amount: parseInt(amount),
      };

      dispatch(cartActions.addToCart(product));
      navigate("/cart");
    } else navigate("/login");
  };

  useEffect(() => {
    dispatch(getTileAsync(id));
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(singleTileActions.resetState());
    };
  }, [dispatch]);

  // console.log(singleTile);

  return (
    <div className="custom-container-md">
      {isLoading ? (
        <div className="h-100vh-header d-flex justify-content-center align-items-center">
          <h4 className="text-center">Učitavanje...</h4>
        </div>
      ) : error ? (
        <div className="py-5 my-5 text-danger">
          <h4>Greska 404</h4>
          <h5>Proizvod nije pronađen.</h5>
        </div>
      ) : (
        <div>
          <ProductGalery
            image={currentImg}
            isOpen={galeryShow}
            onClose={closeGalery}
          />
          <div className="w-100 px-2 navigation-map-text py-3 custom-border-top custom-border-bottom filters-bar d-flex justify-content-between align-items-center">
            {/* name of tile is goint to be rendered dynamicaly */}
            {`Početna > Proizvodi >
            ${singleTile?.name} ${singleTile?.category.name}
            `}
          </div>
          <div className="row g-0 pb-md-5">
            <div className="col-lg-6 pe-lg-4">
              {!showInSpace ? (
                <img
                  onMouseOver={() => setShowInSpace(true)}
                  src={
                    singleTile?.mainImageUrl
                      ? IMAGE_URL + singleTile?.mainImageUrl
                      : noPic
                  }
                  className="product-cover-image"
                  alt="tile-cover"
                />
              ) : (
                <img
                  onMouseLeave={() => setShowInSpace(false)}
                  src={
                    singleTile?.coverImageUrl
                      ? IMAGE_URL + singleTile?.coverImageUrl
                      : noPic
                  }
                  className="product-cover-image hovered"
                  alt="tile-cover"
                />
              )}
              {/* //* Product Image Galery */}
              {singleTile?.galleryImagesUrls && singleTile?.galleryImagesUrls?.length > 0 ? (
                <div>
                  <p className="py-4 py-md-3 m-0 px-3 px-md-0">Galerija</p>
                  <div className="image-catalog d-flex justify-content-start align-items-center flex-wrap">
                    {singleTile?.galleryImagesUrls?.map((n) => (
                      <img
                      key={n}
                        onClick={() => openGalery(n)}
                        src={n ? IMAGE_URL + n : noPic}
                        className="catalog-item"
                        alt="catalog-item"
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <p className="py-4 py-md-3 m-0 px-3 px-lg-0">
                  Galerija je prazna.
                </p>
              )}
            </div>
            <div className="col-lg-6 ps-md-2 px-3 px-md-0 ps-lg-5 pt-0 pt-md-4 product-details">
              <motion.h1
                initial={{ x: 200 }}
                animate={{ x: 0 }}
                transition={{ duration: 0.5 }}
                className="product-details-title py-3 pb-4 py-md-0 pb-md-0"
              >
                {singleTile?.name} <span>{singleTile?.category.name}</span>
              </motion.h1>
              <motion.h5
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="product-details-dimensions mt-4"
              >
                Dimenzije: {singleTile?.width}cm x {singleTile?.length} x{" "}
                {singleTile?.height - 1}mm
              </motion.h5>
              {/* <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="price-container mt-4 w-100 text-start pe-0 mt-lg-4 custom-border-top custom-border-bottom"
              >
                <div className="row g-0">
                  <div className="col-4 d-flex justify-content-center align-items-center">
                    Cena u RSD
                  </div>
                  <div className="col-4 py-4 custom-bg-gray custom-border-right-white d-flex flex-column justify-content-center align-items-center">
                    Veleprodaja:
                    <h5>
                      <span className="fw-bold">{singleTile?.ppsmw}</span> po m
                      <sup>2</sup>
                    </h5>
                  </div>
                  <div className="col-4 py-4 custom-bg-gray h-100 d-flex flex-column justify-content-center align-items-center">
                    Maloprodaja:
                    <h5>
                      <span className="fw-bold">{singleTile?.ppsmr}</span> po m
                      <sup>2</sup>
                    </h5>
                  </div>
                </div>
              </motion.div> */}
              {/* <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="py-4 py-lg-4 custom-border-bottom text-danger"
              >
                <span className="fw-bolder pe-2 text-dark">Napomena:</span>{" "}
                Prikazane cene ne uključuju PDV.
              </motion.div> */}
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="py-4 py-lg-4 custom-border-bottom"
              >
                {singleTile?.description ? singleTile?.description : 'Opis nije dostupan.'}
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="py-4 custom-border-bottom d-flex justify-content-start align-items-center"
              >
                Dodaj
                <input
                  type="number"
                  className="cart-amount mx-3 p-3 pe-0"
                  value={amount}
                  onChange={handleAmountChange}
                />
                kvadrat{amount > 1 && "a"} u korpu
                <button
                  disabled={amount < 1}
                  onClick={addToCartHandler}
                  className="btn ms-4 btn-dark"
                >
                  Dodaj
                </button>
              </motion.div>
            </div>
          </div>
          {/* //! Related products & combinations */}
          {singleTile && status !== "idle" && (
            <CombinationProducts id={singleTile?.id} />
          )}
          {singleTile && status !== "idle" && (
            <RelatedColorProducts
              color={singleTile?.color}
              mainTileId={singleTile?.id}
            />
          )}
          {singleTile && status !== "idle" && (
            <RelatedCategoryProducts
              category={singleTile?.category}
              mainTileId={singleTile?.id}
            />
          )}
          <Newsletter />
        </div>
      )}
    </div>
  );
};

export default ProductDetails;

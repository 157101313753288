import React from "react";
import noPic from "../assets/images/noPic.png";
import { IMAGE_URL } from "../config/apiUrl";

const ProductGalery = ({ isOpen, onClose, image }) => {
  return (
    <div>
      <div
        onClick={onClose}
        className={`galery-overlay d-flex justify-content-center align-items-center ${
          isOpen && "open"
        }`}
      >
        {image && (
          <img
            src={image ? IMAGE_URL + image : noPic}
            alt="catalog-item"
            className={`galery-main-image ${isOpen && "open"}`}
          />
        )}
      </div>
    </div>
  );
};

export default ProductGalery;

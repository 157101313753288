import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginAsync, resetPasswordAsync } from "../store/slices/authSlice";
import { authActions } from "../store/slices/authSlice";
import { validateEmail } from "../helpers/validations";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const {
    status,
    user,
    isLoading,
    error,
    resetPasswordStatus,
    resetPasswordError,
    isLoadingResetPassword,
  } = useSelector((state) => state.auth);
  const [emailMessage, setEmailMessage] = useState(null);
  const [resetPasswordInput, setResetPasswordInput] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState(null);
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [resetPasswordEmailMessage, setResetPasswordEmailEmailMessage] =
    useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmitHandler = (e) => {
    e.preventDefault();

    setEmailMessage(null);
    setPasswordMessage(null);

    if (email.trim().length === 0) {
      setEmailMessage("Potrebno je uneti mejl adresu.");
      return;
    }

    if (!validateEmail(email)) {
      setEmailMessage("Neispravna email adresa.");
      return;
    }

    if (password.trim().length < 5) {
      setPasswordMessage("Potrebno je uneti šifru.");
      return;
    }

    if (password.trim().length < 5) {
      setPasswordMessage("Neispravna šifra.");
      return;
    }

    dispatch(loginAsync({ email, password }));
  };

  useEffect(() => {
    if (user) {
      dispatch(authActions.resetStatus());
      localStorage.setItem("user", JSON.stringify(user));
      navigate("/home");
    }
  }, [user, status, dispatch, navigate]);

  const resetPasswordHandler = () => {
    setResetPasswordEmailEmailMessage(null);

    if (
      resetPasswordEmail.trim().length === 0 ||
      !validateEmail(resetPasswordEmail)
    ) {
      setResetPasswordEmailEmailMessage("Neispravan email");
      return;
    }

    dispatch(resetPasswordAsync({ email: resetPasswordEmail }));
  };

  useEffect(() => {
    if (resetPasswordStatus === "resetPasswordSuccess") {
      setResetPasswordInput(false);
      dispatch(authActions.resetResetPasswordStatus());
    }
  }, [resetPasswordStatus, dispatch]);

  return (
    <div className="custom-container-md pb-5">
      <div className="w-100 px-2 navigation-map-text py-3 custom-border-top custom-border-bottom filters-bar d-flex justify-content-between align-items-center">
        {"Prijava"}
      </div>
      <div className="row g-0 py-2 py-lg-5 custom-border-top custom-border-bottom">
        <div className="col-lg-3">
          {/* <img src={tile} className='login-cover-img'/> */}
        </div>
        <div className="col-lg-6 p-4 d-flex justify-content-center align-items-center flex-column">
          <form className="login-form w-md-50" onSubmit={onSubmitHandler}>
            {!resetPasswordInput ? (
              <>
                <label>Email</label>
                <br></br>
                <input
                  className="py-2 px-3 w-100"
                  type="text"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <br />
                {emailMessage && <p className="text-danger">{emailMessage}</p>}
                <br></br>
                {/* <br></br> */}
                <label>Password</label>
                <br></br>
                <input
                  className="py-2 px-3 w-100"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <br />
                {passwordMessage && (
                  <p className="text-danger">{passwordMessage}</p>
                )}
                <br></br>
                {/* <br></br> */}
                {error && <p className="mt-2 mb-2 p-2 text-danger">{error.Message}</p>}
                <div className="d-flex justify-content-end pb-2">
                  {/* <Link to="/home" className="text-dark">
                Zaboravili ste šifru?
              </Link> */}
                  <p
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => setResetPasswordInput(true)}
                  >
                    Zaboravili ste šifru?
                  </p>
                </div>
                <input
                  className="btn mt-2 btn-dark w-100 py-3 px-3"
                  type="submit"
                  value={isLoading ? "Verifikacija..." : "Prijavi se"}
                />
              </>
            ) : (
              <>
                <label>Email</label>
                <br></br>
                <input
                  className="py-2 px-3 w-100"
                  type="email"
                  onChange={(e) => setResetPasswordEmail(e.target.value)}
                  value={resetPasswordEmail}
                />
                <br />
                {resetPasswordEmailMessage && (
                  <p>{resetPasswordEmailMessage}</p>
                )}
                {resetPasswordError && <p>{resetPasswordError.Message}</p>}
                <br />
                <input
                  className="btn mt-2 btn-dark w-100 py-3 px-3"
                  type="button"
                  value={
                    isLoadingResetPassword
                      ? "Resetovanje..."
                      : "Resetujte lozinku"
                  }
                  onClick={resetPasswordHandler}
                />
                <input
                  className="btn mt-2 btn-dark w-100 py-3 px-3"
                  type="button"
                  value={"Nazad na login"}
                  onClick={() => setResetPasswordInput(false)}
                />
              </>
            )}
          </form>
          <div className="mt-5 mb-5 text-center login-to-register custom-border-top pt-5">
            Niste registrovani?
            <Link
              to="/register"
              className="d-block text-dark text-decoration-underline"
            >
              Kreirajte nalog sada
            </Link>
          </div>
        </div>
        <div className="col-lg-3">
          {/* <img src={tile2} className='login-cover-img'/> */}
        </div>
      </div>
    </div>
  );
};

export default Login;

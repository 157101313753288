import React, { useEffect } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { deleteTileCategoryAsync } from "../store/slices/tileCategorySlice";

const CategoryAsItem = ({ category }) => {
  const dispatch = useDispatch();

  //* Global state
  const { deleteCategoryStatus, deleteCategoryError, deleteCategoryIsLoading } =
    useSelector((s) => s.tileCategory);

  const clickHandler = () => {
    if (window.confirm("Da li ste sigurni?"))
      dispatch(deleteTileCategoryAsync(category.id));
  };

  return (
    <div>
      <div
        className={`bg-white custom-border-bottom category-asitem d-flex justify-content-between align-items-center py-2 px-3`}
      >
        <div className="category-asitem-title pe-5">{category.name}</div>
        <div className="">
          <button
            onClick={clickHandler}
            className="btn product-asitem-icon-btn text-danger"
          >
            <AiOutlineDelete className="" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CategoryAsItem;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tileCategoryActions } from "../store/slices/tileCategorySlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAllTilesAsync } from "../store/slices/tileSlice";

const ProductFilters = ({ isOpen, onClose }) => {
  const [ppsmw, setPpsmw] = useState({ min: 0, max: 0 });
  const [ppsmr, setPpsmr] = useState({ min: 0, max: 0 });
  const [length, setLength] = useState({ min: 0, max: 0 });
  const [width, setWidth] = useState({ min: 0, max: 0 });
  const [height, setHeight] = useState({ min: 0, max: 0 });
  //* Redux State
  const { tileCategories, status, isLoading } = useSelector(
    (s) => s.tileCategory
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  //* Local state
  const [colors, setColors] = useState([]);

  useEffect(() => {
    const minPpsmw = searchParams.get("minPpsmw");
    const maxPpsmw = searchParams.get("maxPpsmw");
    const minPpsmr = searchParams.get("minPpsmr");
    const maxPpsmr = searchParams.get("maxPpsmr");
    const minLength = searchParams.get("minLength");
    const maxLength = searchParams.get("maxLength");
    const minWidth = searchParams.get("minWidth");
    const maxWidth = searchParams.get("maxWidth");
    const minHeight = searchParams.get("minHeight");
    const maxHeight = searchParams.get("maxHeight");
    const colors = searchParams.getAll("colors");

    if (minPpsmw) setPpsmw((oldState) => ({ ...oldState, min: minPpsmw }));
    if (maxPpsmw) setPpsmw((oldState) => ({ ...oldState, max: maxPpsmw }));
    if (minPpsmr) setPpsmr((oldState) => ({ ...oldState, min: minPpsmr }));
    if (maxPpsmr) setPpsmr((oldState) => ({ ...oldState, max: maxPpsmr }));
    if (minLength) setLength((oldState) => ({ ...oldState, min: minLength }));
    if (maxLength) setLength((oldState) => ({ ...oldState, max: maxLength }));
    if (minWidth) setWidth((oldState) => ({ ...oldState, min: minHeight }));
    if (maxWidth) setWidth((oldState) => ({ ...oldState, max: maxHeight }));
    if (colors && colors.length > 0) setColors(colors);
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (status === "tileCategoriesSuccess") {
      const categories = searchParams.getAll("categories");
      if (categories && categories.length > 0)
        dispatch(
          tileCategoryActions.setCategoriesChecked(categories.map(Number))
        );

      dispatch(tileCategoryActions.resetStatus());
    }
  }, [dispatch, searchParams, status]);

  const handleColorChoice = (color) => {
    if (colorsInclude(color)) {
      setColors([...colors].filter((c) => c !== color));
    } else {
      let newArr = [color];
      setColors([...colors].concat(newArr));
    }
  };

  const colorsInclude = (color) => colors.includes(color);

  const createUrlHandler = () => {
    let searchUrl = `/products?minPpsmw=${ppsmw.min}&maxPpsmw=${ppsmw.max}&minPpsmr=${ppsmr.min}&maxPpsmr=${ppsmr.max}&minLength=${length.min}&maxLength=${length.max}&minWidth=${width.min}&maxWidth=${width.max}&minHeight=${height.min}&maxHeight=${height.max}`;

    colors.forEach((color) => (searchUrl += `&colors=${color}`));
    tileCategories.forEach(
      (category) =>
        category.isChecked && (searchUrl += `&categories=${category.id}`)
    );

    return searchUrl;
  };

  const applyFilters = () => {
    navigate(createUrlHandler());

    const selectedCategories = tileCategories
      .filter((x) => x.isChecked === true)
      .map((x) => x.id);

    dispatch(
      getAllTilesAsync({
        ppsmw,
        ppsmr,
        length,
        width,
        height,
        colors,
        selectedCategories,
      })
    );

    onClose();
  };

  const resetFiltersHandler = () => {
    setPpsmw({ min: 0, max: 0 });
    setPpsmr({ min: 0, max: 0 });
    setLength({ min: 0, max: 0 });
    setWidth({ min: 0, max: 0 });
    setHeight({ min: 0, max: 0 });
    setColors([]);
    dispatch(tileCategoryActions.resetIsChecked());

    onClose();
    dispatch(getAllTilesAsync({}));
    navigate("/products");
  };

  return (
    <>
      <div
        onClick={onClose}
        className={`products-overlay ${isOpen && "open"}`}
      ></div>
      <div className={`products-filters-container ${isOpen && "open"}`}>
        <div className="products-filters-container-inner w-100 py-5 px-3 d-flex flex-column">
          <h5 className="filter-heading">Filteri</h5>
          <span className="filter-title">Kategorije</span>
          <div className="p-3 custom-bg-gray">
            {isLoading
              ? "Učitavanje..."
              : tileCategories &&
                tileCategories.map((n) => (
                  <div key={n.id}>
                    <input
                      type="checkbox"
                      className="me-2"
                      onChange={() =>
                        dispatch(tileCategoryActions.changeChecked([n.id]))
                      }
                      checked={n.isChecked}
                    />{" "}
                    {n.name}
                    <br></br>
                  </div>
                ))}
          </div>
          <span className="filter-title mt-2">Cena</span>
          <div className="p-3 custom-bg-gray pt-4">
            <b>Veleprodajna cena:</b>
            <br></br>
            <div className="p-2"></div>
            <div className="w-100 py-2 ps-3 d-flex align-items-end gap-3 custom-border-bottom border-light pb-4">
              Od
              <input
                type="number"
                className="price-input"
                value={ppsmw.min}
                onChange={(e) => setPpsmw({ ...ppsmw, min: e.target.value })}
              />
              do
              <input
                type="number"
                className="price-input"
                value={ppsmw.max}
                onChange={(e) => setPpsmw({ ...ppsmw, max: e.target.value })}
              />
            </div>
            <br></br>
            <b>Maloprodajna cena:</b>
            <br></br>
            <div className="p-2"></div>
            <div className="w-100 py-2 ps-3 d-flex align-items-end gap-3 pb-4">
              Od
              <input
                type="number"
                className="price-input"
                value={ppsmr.min}
                onChange={(e) => setPpsmr({ ...ppsmr, min: e.target.value })}
              />
              do
              <input
                type="number"
                className="price-input"
                value={ppsmr.max}
                onChange={(e) => setPpsmr({ ...ppsmr, max: e.target.value })}
              />
            </div>
          </div>
          <span className="filter-title mt-2">Dimenzije</span>
          <div className="p-3 pt-4 custom-bg-gray">
            <b>Dužina:</b>
            <br></br>
            <div className="p-2"></div>
            <div className="w-100 py-2 ps-3 d-flex align-items-end gap-3 custom-border-bottom border-light pb-4">
              Od
              <input
                type="number"
                className="price-input"
                value={length.min}
                onChange={(e) => setLength({ ...length, min: e.target.value })}
              />
              do
              <input
                type="number"
                className="price-input"
                value={length.max}
                onChange={(e) => setLength({ ...length, max: e.target.value })}
              />
            </div>
            <br></br>
            <b>Širina:</b>
            <br></br>
            <div className="p-2"></div>
            <div className="w-100 py-2 ps-3 d-flex align-items-end gap-3 custom-border-bottom border-light pb-4">
              Od
              <input
                type="number"
                className="price-input"
                value={width.min}
                onChange={(e) => setWidth({ ...width, min: e.target.value })}
              />
              do
              <input
                type="number"
                className="price-input"
                value={width.max}
                onChange={(e) => setWidth({ ...width, max: e.target.value })}
              />
            </div>
            <br></br>
            <b>Debljina:</b>
            <br></br>
            <div className="p-2"></div>
            <div className="w-100 py-2 ps-3 d-flex align-items-end gap-3 pb-4">
              Od
              <input
                type="number"
                className="price-input"
                value={height.min}
                onChange={(e) => setHeight({ ...height, min: e.target.value })}
              />
              do
              <input
                type="number"
                className="price-input"
                value={height.max}
                onChange={(e) => setHeight({ ...height, max: e.target.value })}
              />
            </div>
          </div>
          <span className="filter-title mt-2">Boje</span>
          <div className="p-2 py-4 custom-bg-gray gap-1 d-flex justify-content-start align-items-start flex-wrap">
            <div
              onClick={() => handleColorChoice("white")}
              className={`custom-border-all color-option color-option-white ${
                colorsInclude("white") && "active"
              }`}
            ></div>
            <div
              onClick={() => handleColorChoice("black")}
              className={`custom-border-all color-option color-option-black ${
                colorsInclude("black") && "active"
              }`}
            ></div>
            <div
              onClick={() => handleColorChoice("red")}
              className={`custom-border-all color-option color-option-red ${
                colorsInclude("red") && "active"
              }`}
            ></div>
            <div
              onClick={() => handleColorChoice("green")}
              className={`custom-border-all color-option color-option-green ${
                colorsInclude("green") && "active"
              }`}
            ></div>
            <div
              onClick={() => handleColorChoice("blue")}
              className={`custom-border-all color-option color-option-blue ${
                colorsInclude("blue") && "active"
              }`}
            ></div>
            <div
              onClick={() => handleColorChoice("yellow")}
              className={`custom-border-all color-option color-option-yellow ${
                colorsInclude("yellow") && "active"
              }`}
            ></div>
            <div
              onClick={() => handleColorChoice("purple")}
              className={`custom-border-all color-option color-option-purple ${
                colorsInclude("purple") && "active"
              }`}
            ></div>
            <div
              onClick={() => handleColorChoice("brown")}
              className={`custom-border-all color-option color-option-brown ${
                colorsInclude("brown") && "active"
              }`}
            ></div>
            <div
              onClick={() => handleColorChoice("orange")}
              className={`custom-border-all color-option color-option-orange ${
                colorsInclude("orange") && "active"
              }`}
            ></div>
            <div
              onClick={() => handleColorChoice("gray")}
              className={`custom-border-all color-option color-option-gray ${
                colorsInclude("gray") && "active"
              }`}
            ></div>
            {/* <div className="p-4 bg-danger"></div> */}
          </div>
          <div className="w-100 d-flex justify-content-between align-items-center my-3">
            <button
              className="mt-4 p-3 px-5 btn btn-dark"
              onClick={applyFilters}
            >
              Pretraži
            </button>
            <button
              className="mt-4 p-3 px-5 btn custom-border-all"
              onClick={resetFiltersHandler}
            >
              Poništi
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductFilters;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import authUser from "../store/selectors/auth/authUser";
import { contactActions, contactAsync } from "../store/slices/contactSlice";
import { validateEmail } from "../helpers/validations";

const Contact = () => {
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const [firstNameMessage, setFirstNameMessage] = useState(null);
  const [lastNameMessage, setLastNameMessage] = useState(null);
  const [emailMessage, setEmailMessage] = useState(null);
  const [phoneNumberMessage, setPhoneNumberMessage] = useState(null);
  const [commentMessage, setCommentMessage] = useState(null);

  //* Component state
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    comment: "",
  });

  //* Global state
  const user = useSelector(authUser);
  const { isLoading, status, error } = useSelector((s) => s.contact);

  const handleValueChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const submitHandler = (e) => {
    e.preventDefault();

    setFirstNameMessage(null);
    setLastNameMessage(null);
    setEmailMessage(null);
    setPhoneNumberMessage(null);
    setCommentMessage(null);

    if (user && formValues.comment.trim().length === 0) {
      setCommentMessage("Polje za poruku ne sme biti prazno");
      return;
    }

    if (!user && formValues.firstName.trim().length === 0) {
      setFirstNameMessage("Polje za ime ne sme biti prazno");
      return;
    }

    if (!user && formValues.lastName.trim().length === 0) {
      setLastNameMessage("Polje za prezime ne sme biti prazno");
      return;
    }

    if (
      !user &&
      (formValues.email.trim().length === 0 || !validateEmail(formValues.email))
    ) {
      setEmailMessage("Neispravan email");
      return;
    }

    if (!user && formValues.phone.trim().length === 0) {
      setPhoneNumberMessage("Polje za telefon ne sme biti prazno");
      return;
    }

    if (!user && formValues.comment.trim().length === 0) {
      setCommentMessage("Polje za poruku ne sme biti prazno");
      return;
    }

    //TODO - Form validation

    dispatch(
      contactAsync({
        firstName: user ? user.user.firstName : formValues.firstName,
        lastName: user ? user.user.lastName : formValues.lastName,
        email: user ? user.user.email : formValues.email,
        phone: user ? user.user.phoneNumber : formValues.phone,
        comment: formValues.comment,
      })
    );
  };

  useEffect(() => {
    if (status === "contactSuccess") {
      setSuccess(true);
      dispatch(contactActions.resetStatus());
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [dispatch, status]);

  return (
    <div className="custom-container-md pb-5">
      <div className="w-100 px-2 navigation-map-text py-3 custom-border-top custom-border-bottom filters-bar d-flex justify-content-between align-items-center">
        {"Početna > Kontakt"}
      </div>
      <div className="mh-100vh-header-map">
        <div className="row g-0">
          <div className="col-xl-7 py-4 py-lg-5 px-lg-3 px-4">
            {success ? (
              <h2 className="text-success pb-5">Uspešno poslata poruka!</h2>
            ) : (
              <div>
                <h2>Kako do nas?</h2>
                <div>
                  <p className="pe-5 py-3 mb-3">
                    Salon keramike AM je poznata i renomirana firma u Tutinu
                    koja se bavi prodajom keramičkih pločica najvišeg kvaliteta.
                    U nastavku ovog sastava, opisat ćemo kako do nas doći, bez
                    obzira gde se nalazite. <br></br>
                    <br></br>Ako dolazite iz drugih gradova, preporučujemo da
                    koristite Google Maps aplikaciju koja će Vas usmeriti na
                    najbolju rutu do nas. Salon keramike AM ima i besplatni
                    parking za sve svoje kupce, što znači da nećete imati
                    problema s pronalaskom mesta za parkiranje. Naš parking se
                    nalazi odmah ispred salona i uvek ima dovoljno mesta za
                    sve.
                    <br></br>
                    <br></br>Takođe, ako Vam je potrebna dodatna pomoć, naš
                    stručni tim Vam stoji na raspolaganju i rado će Vam dati
                    detaljne upute kako do nas doći. Sve što trebate je da nas
                    kontaktirate putem telefona ili e-maila, a mi ćemo Vam rado
                    pomoći. <br></br>
                    <br></br>Ukratko, put do Salona keramike AM je vrlo
                    jednostavan i brz, bez obzira odakle dolazite. Nadamo se da
                    će vam ovaj sastav pomoći u planiranju posete našem salonu
                    i da ćemo se uskoro videti.
                  </p>
                </div>
              </div>
            )}
            Unesite informacije i poruku:
            <form onSubmit={submitHandler} className="w-100 pt-4">
              Ime<br></br>
              <input
                name="firstName"
                disabled={user !== null}
                defaultValue={
                  user ? user.user.firstName : formValues.firstName || ""
                }
                onChange={handleValueChange}
                className="w-md-50 p-2"
                type="text"
              />
              <br />
              {firstNameMessage && <p>{firstNameMessage}</p>}
              <br></br>
              {/* <br></br> */}
              Prezime<br></br>
              <input
                name="lastName"
                disabled={user !== null}
                defaultValue={
                  user ? user.user.lastName : formValues.lastName || ""
                }
                onChange={handleValueChange}
                className="w-md-50 p-2"
                type="text"
              />
              <br />
              {lastNameMessage && <p>{lastNameMessage}</p>}
              <br></br>
              {/* <br></br> */}
              E-mail adresa<br></br>
              <input
                name="email"
                disabled={user !== null}
                defaultValue={user ? user.user.email : formValues.email || ""}
                onChange={handleValueChange}
                className="w-md-50 p-2"
                type="text"
              />
              <br />
              {emailMessage && <p>{emailMessage}</p>}
              <br></br>
              {/* <br></br> */}
              Broj telefona<br></br>
              <input
                name="phone"
                disabled={user !== null}
                defaultValue={
                  user ? user.user.phoneNumber : formValues.phone || ""
                }
                onChange={handleValueChange}
                className="w-md-50 p-2"
                type="text"
              />
              <br />
              {phoneNumberMessage && <p>{phoneNumberMessage}</p>}
              <br></br>
              {/* <br></br> */}
              Poruka<br></br>
              <textarea
                name="comment"
                defaultValue={formValues.comment || ""}
                onChange={handleValueChange}
                className="w-md-50 p-2"
                rows={5}
              />
              <br />
              {commentMessage && <p>{commentMessage}</p>}
              <br></br>
              {/* <br></br> */}
              {error ? <p className="pb-4 text-danger">{error.Message}</p> : ""}
              <input
                type="submit"
                className="w-md-25 py-2 btn btn-dark"
                value={isLoading ? "Slanje..." : "Pošalji"}
              />
            </form>
          </div>
          <div className="col-xl-5 contact-hero-image"></div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

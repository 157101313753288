import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contactService from "../../services/contactService";

const initialState = {
  status: "idle",
  isLoading: false,
  error: null,
};

export const contactAsync = createAsyncThunk(
  "contact/contactAsync",
  async (payload, thunkAPI) => {
    try {
      return await contactService.contact(payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    //! Post subscription
    builder.addCase(contactAsync.pending, (state) => {
      state.status = "idle";
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(contactAsync.fulfilled, (state) => {
      state.status = "contactSuccess";
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(contactAsync.rejected, (state, action) => {
      state.status = "idle";
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const contactActions = contactSlice.actions;
export const contactReducers = contactSlice.reducer;

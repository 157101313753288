import { API_URL } from "../config/apiUrl";
import CustomAxiosInsance from "../middleware/axios/connectionErrorMiddleware";

const api = CustomAxiosInsance();
api.defaults.baseURL = API_URL;

const methods = {
  getAllTileCategories: () =>
    api.get("tilesCategory").then((response) => response.data),
  createCategory: (payload) =>
    api.post("tilesCategory", payload).then((response) => response.data),
  deleteCategory: (payload) =>
    api.delete("tilesCategory/" + payload).then((response) => response.data),
};

export default methods;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  tileCategoryActions,
  createTileCategoryAsync,
} from "../store/slices/tileCategorySlice";

const AdminPanelAddCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //* Component state
  const [catName, setCatName] = useState("");

  //* Global state
  const { createCategoryError, createCategoryIsLoading, createCategoryStatus } =
    useSelector((s) => s.tileCategory);

  useEffect(() => {
    if (createCategoryStatus === "createTileCategorySuccess") {
      dispatch(tileCategoryActions.resetCreateCategoryStatus());
      navigate("/admin-panel/categories");
    }
  }, [dispatch, navigate, createCategoryStatus]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createTileCategoryAsync({ name: catName }));
  };

  const resetHandler = (e) => {
    e.preventDefault();
    setCatName("");
  };

  return (
    <div>
      <h2 className="px-4 px-md-5 custom-bg-gray py-4 custom-border-bottom">
        Dodavanje kategorije
      </h2>
      <form onSubmit={submitHandler} className="w-100">
        <div className="w-100 row g-0">
          <div className="col-12 p-4 pt-3 admin-intro custom-border-bottom">
            Poštovani, kao administrator www.am-ceramics.rs imate mogućnost
            content management-a. Obzirom na to, molimo Vas da prilikom unosa
            podataka o novom proizvodu ili izmene starih podataka pažljivo
            izaberete proizvod za izmenu ili pažljivo unosite podatke za
            dodavanje.
            <br></br>
            <br></br>U slučaju nedoumica kontaktirati tim Tehničke podrške.
          </div>
        </div>
        <div className="col-lg-7 ps-4 ps-md-5 p-4 custom-border-bottom">
          <h5>Naziv kategorije</h5>
          <input
            value={catName}
            onChange={(e) => setCatName(e.target.value)}
            type="text"
            className="add-product-input mt-3"
          />
          {createCategoryError ? <p className="pt-3 text-danger">{createCategoryError.Message}</p> : ''}
        </div>
        <div className="col-lg-5"></div>
        <div className="col-lg-7 mb-5 p-4 px-4 px-md-5 d-flex align-items-center justify-content-start gap-2">
          <input
            disabled={!catName}
            type="submit"
            className="btn px-5 py-2 btn-success"
            value={createCategoryIsLoading ? "Učitavanje..." : "Potvrdi"}
          />
          <button
            disabled={!catName}
            onClick={resetHandler}
            className="btn px-5 py-2 btn-danger"
          >
            Poništi
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminPanelAddCategory;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSubscriptionAsync,
  newsletterActions,
} from "../store/slices/newsletterSlice";
import { validateEmail } from "../helpers/validations";

const Newsletter = () => {
  //* Component state
  const [mail, setMail] = useState("");
  const [success, setSuccess] = useState(false);
  const [isValid, setIsValid] = useState(false);

  //* Global state
  const { status, isLoading, error } = useSelector((s) => s.newsletter);

  const dispatch = useDispatch();

  //* Input validation
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setMail(inputValue);
    setIsValid(validateEmail(inputValue));
  };

  //* Form submission handler
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createSubscriptionAsync({ email: mail }));
  };

  useEffect(() => {
    if (status === "createSubscriptionSuccess") {
      setSuccess(true);
      dispatch(newsletterActions.resetStatus());
    }
  }, [status, dispatch]);

  return (
    <div className="newsletter-container mb-md-5">
      <div className="p-2 p-lg-5 newsletter-overlay d-flex justify-content-start align-items-center">
        {success ? (
          <div className="row py-5 g-0 w-100">
            <div className="col-lg-7 p-lg-5 p-2">
              <h1 className="newsletter-title">
                Uspešna prijava na naš newsletter!
              </h1>
            </div>
          </div>
        ) : (
          <div className="row py-5 g-0 w-100">
            <div className="col-lg-7 p-lg-5 p-2">
              {error ? (
                <div>
                  <h1 className="newsletter-title text-warning">
                    {error.Message}
                  </h1>
                  <p>Molimo Vas unesite drugu mejl adresu.</p>
                </div>
              ) : (
                <div>
                  <h1 className="newsletter-title">
                    Prijavite se na naš newsletter
                  </h1>
                  <p>i budite u toku sa svim dešavanjima na našoj stranici!</p>
                </div>
              )}
              <form
                onSubmit={submitHandler}
                className="mt-5 newsletter-form w-100 d-flex justify-content-start align-items-end gap-1"
              >
                <input
                  values={mail}
                  onChange={handleInputChange}
                  type="text"
                  className="bg-transparent"
                />
                <input
                  disabled={!isValid}
                  type="submit"
                  className="btn btn-light px-3 py-2"
                  value={!isLoading ? "Prijavi se" : "Učitavanje..."}
                />
              </form>
            </div>
            <div className="col-lg-5 p-lg-5 p-2"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Newsletter;

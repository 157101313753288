import React, { useEffect, useState } from "react";
import Dropdown from "./shared/Dropdown";
import { FiMenu, FiHome } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import { IoClose } from "react-icons/io5";

const optionsProducts = [
  { path: "products", name: "Svi proizvodi" },
  { path: "add-product", name: "Dodavanje proizvoda" },
];
const optionsCategories = [
  { path: "categories", name: "Sve kategorije" },
  { path: "add-category", name: "Dodavanje kategorije" },
];

const optionsUsers = [{ path: "user-stats", name: "Statistika" }];

const AdminContainer = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { pathname } = useLocation();

  const handleToggle = () => {
    document.body.style.overflow = menuOpen ? "visible" : "hidden";
    setMenuOpen((s) => !s);
  };

  const checkWindowSize = () => {
    if (window.innerWidth >= 1024) setMenuOpen(false);
  };

  useEffect(() => {
    setMenuOpen(false);
  }, [pathname]);

  useEffect(() => {
    document.body.style.overflow = "visible";

    setMenuOpen(false);
  }, [pathname]);

  useEffect(() => {
    window.addEventListener("resize", checkWindowSize);
    checkWindowSize();

    return () => {
      window.removeEventListener("resize", checkWindowSize);
    };
  }, []);

  return (
    <div className="container-fluid p-0 d-flex">
      <div className={`admin-menu custom-bg-dark p-3 ${menuOpen && "open"}`}>
        <div className="py-4">
          <div className="text-light administration-btns">
            Administracija{" "}
            <button onClick={handleToggle} className="administration-menu-close">
              <IoClose />
            </button>
          </div>
        </div>
        <div className="admin-links custom-border-top text-light pt-2">
          <Dropdown title="Proizvodi" items={optionsProducts} />
          <Dropdown title="Kategorije" items={optionsCategories} />
          <Dropdown title="Korisnici" items={optionsUsers} />
        </div>
      </div>
      <div className="admin-content custom-bg-light">
        <div className="admin-content-inner">
          <div className="custom-container-md footer-bottom custom-border-bottom py-1">
            <p className="text-end w-100 pe-3 pe-lg-0">Administracija</p>
          </div>
          <div className="side-menu-icon-container custom-bg-light w-100 custom-border-bottom p-3">
            <button onClick={handleToggle} className="btn text-dark">
              <FiMenu className="admin-res-btn side-menu" />
            </button>
            <Link className="btn text-dark text-decoration-none" to="/home">
              <FiHome className="admin-res-btn home" />
            </Link>
          </div>
          {/* //* Here we apply the elements of a specific panel in administration */}
          {children}
        </div>
      </div>
    </div>
  );
};

export default AdminContainer;

import React, { useEffect } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { getAllTileCategoriesAsync } from "../store/slices/tileCategorySlice";
import { getAdminTilesAsync } from "../store/slices/adminTilesSlice";
import {
  createTileActions,
  createTileAsync,
} from "../store/slices/createTileSlice";
import { useNavigate } from "react-router-dom";

const AdminPanelAddProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //* Component state
  const [categoryId, setCategory] = useState(0);
  const [combinations, setCombinations] = useState([]);
  const [files, setFiles] = useState([]);
  const [thumb, setThumb] = useState(null);
  const [cover, setCover] = useState(null);
  const [color, setColor] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [retailPrice, setRetailPrice] = useState(0);
  const [wholesalePrice, setWholesalePricePrice] = useState(0);
  const [length, setLength] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [errorMessage, setError] = useState(null);

  //* Global state
  const { tileCategories, status, error, isLoading } = useSelector(
    (s) => s.tileCategory
  );
  const { isLoading: tilesLoading, tiles } = useSelector((s) => s.adminTiles);

  //* Colors handling
  const handleColorChoice = (color) => {
    setColor(color);
  };

  const colorsInclude = (colorOpt) => color === colorOpt;

  //* Combinations handling
  const handleCombinations = (id) => {
    if (combinationsInclude(id)) {
      setCombinations([...combinations].filter((c) => c !== id));
    } else {
      let newArr = [id];
      setCombinations([...combinations].concat(newArr));
    }
  };

  const combinationsInclude = (color) => combinations.includes(color);

  //* Dropzone handling
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
    },
  });

  const thumbs = files.map((n) => (
    <p key={n.name} className="text-primary text-center">
      {n.name}
    </p>
  ));

  //* CategoryHandling
  const categoryHandler = (id) => {
    setCategory(id);
  };

  const formSubmission = (e) => {
    e.preventDefault();

    setError(null);

    if (name.trim().length === 0) {
      setError("Potrebno je uneti naziv proizvoda.");
      return;
    }
    if (length <= 0) {
      setError("Potrebno je uneti ispravnu dužinu.");
      return;
    }
    if (width <= 0) {
      setError("Potrebno je uneti ispravnu širinu.");
      return;
    }
    if (height <= 0) {
      setError("Potrebno je uneti ispravnu debljinu.");
      return;
    }
    if (retailPrice <= 0) {
      setError("Potrebno je uneti ispravnu maloprodajnu cenu.");
      return;
    }
    if (wholesalePrice <= 0) {
      setError("Potrebno je uneti ispravnu veleprodajnu cenu.");
      return;
    }
    if (color.trim().length === 0) {
      setError("Potrebno je definisati boju proizvoda.");
      return;
    }
    if (categoryId === 0) {
      setError("Potrebno je izabrati kategoriju proizvoda.");
      return;
    }

    const formData = new FormData();

    formData.append(`name`, name);
    formData.append(`color`, color);
    formData.append(`tileCategoryId`, categoryId);
    formData.append(`PPSMR`, retailPrice);
    formData.append(`PPSMW`, wholesalePrice);
    formData.append(`height`, height);
    formData.append(`length`, length);
    formData.append(`width`, width);
    formData.append(`description`, desc);
    formData.append(`mainImage`, thumb);
    formData.append(`coverImage`, cover);
    //! append gallery
    for (let i = 0; i < files.length; i++) {
      formData.append("gallery", files[i]);
    }
    //! append combinations
    combinations.forEach((combination, index) => {
      formData.append(`combinations[${index}]`, combination);
    });

    // for (const [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }
    dispatch(createTileAsync(formData));
  };

  const resetFormHandler = (e) => {
    e.preventDefault()
    setError(null);
    setCategory(0);
    setCombinations([]);
    setFiles([]);
    setThumb(null);
    setCover(null);
    setColor("");
    setName("");
    setDesc("");
    setRetailPrice(0);
    setWholesalePricePrice(0);
    setLength(0);
    setHeight(0);
    setWidth(0);
  };

  useEffect(() => {
    dispatch(getAllTileCategoriesAsync());
    dispatch(getAdminTilesAsync());
  }, [dispatch]);

  const { isLoading: createLoading, status: createStatus } = useSelector(
    (s) => s.createTile
  );

  useEffect(() => {
    if (createStatus === "createTileAsyncSuccess") {
      dispatch(createTileActions.resetStatus());
      navigate("/admin-panel/products");
    }
  }, [createStatus]);

  return (
    <div className="">
      <h2 className="px-4 px-md-5 custom-bg-gray m-0 py-4 custom-border-bottom">
        Dodavanje proizvoda
      </h2>
      <form onSubmit={formSubmission} className="w-100">
        <div className="w-100 row g-0">
          <div className="col-12 p-4 admin-intro custom-border-bottom">
            Poštovani, kao administrator www.am-ceramics.rs imate mogućnost
            content management-a. Obzirom na to, molimo Vas da prilikom unosa
            podataka o novom proizvodu ili izmene starih podataka pažljivo
            izaberete proizvod za izmenu ili pažljivo unosite podatke za
            dodavanje.
            <br></br>
            <br></br>U slučaju nedoumica kontaktirati tim Tehničke podrške.
          </div>
          <div className="col-lg-7 ps-4 ps-md-5 p-4 custom-border-bottom">
            <h5>Naziv proizvoda</h5>
            <input
              onChange={(e) => setName(e.target.value)}
              value={name}
              type="text"
              className="add-product-input mt-3"
            />
          </div>
          <div className="col-lg-5"></div>
          <div className="col-lg-7 ps-4 ps-md-5 p-4 px-5 custom-border-bottom">
            <h5>Kategorija proizvoda</h5>
            <div className="py-3">
              {isLoading
                ? "Učitavanje..."
                : status === "tileCategoriesSuccess"
                ? tileCategories.map((n) => (
                    <div key={"cat" + n?.id} className="d-flex align-items-center gap-2">
                      <input
                        checked={n.id === categoryId}
                        onChange={() => categoryHandler(n.id)}
                        type="checkbox"
                      />
                      {n.name}
                    </div>
                  ))
                : error && "Greška..."}
            </div>
          </div>
          <div className="col-lg-5"></div>
          <div className="col-lg-7 ps-4 ps-md-5 p-4 custom-border-bottom">
            <h5>Dimenzije proizvoda</h5>
            <div className="py-2">
              <p>Dužina</p>
              <input
                onChange={(e) => setLength(e.target.value)}
                value={length}
                type="number"
                className="add-product-input-dimension mt-2"
              />
            </div>
            <div className="py-4">
              <p>Širina</p>
              <input
                onChange={(e) => setWidth(e.target.value)}
                value={width}
                type="number"
                className="add-product-input-dimension mt-2"
              />
            </div>
            <div className="py-2">
              <p>Debljina</p>
              <input
                onChange={(e) => setHeight(e.target.value)}
                value={height}
                type="number"
                className="add-product-input-dimension mt-2"
              />
            </div>
          </div>
          <div className="col-lg-5"></div>
          <div className="col-lg-7 ps-4 ps-md-5 p-4 custom-border-bottom">
            <h5>Boja proizvoda</h5>
            <p className="py-3">Molimo Vas, izaberite boju proizvoda:</p>
            <div className="d-flex gap-2 py-2 flex-wrap">
              <div
                onClick={() => handleColorChoice("black")}
                className={`custom-border-all color-option color-option-black ${
                  colorsInclude("black") && "active"
                }`}
              ></div>
              <div
                onClick={() => handleColorChoice("white")}
                className={`custom-border-all color-option color-option-white ${
                  colorsInclude("white") && "active"
                }`}
              ></div>
              <div
                onClick={() => handleColorChoice("gray")}
                className={`custom-border-all color-option color-option-gray ${
                  colorsInclude("gray") && "active"
                }`}
              ></div>
              <div
                onClick={() => handleColorChoice("red")}
                className={`custom-border-all color-option color-option-red ${
                  colorsInclude("red") && "active"
                }`}
              ></div>
              <div
                onClick={() => handleColorChoice("blue")}
                className={`custom-border-all color-option color-option-blue ${
                  colorsInclude("blue") && "active"
                }`}
              ></div>
              <div
                onClick={() => handleColorChoice("green")}
                className={`custom-border-all color-option color-option-green ${
                  colorsInclude("green") && "active"
                }`}
              ></div>
              <div
                onClick={() => handleColorChoice("yellow")}
                className={`custom-border-all color-option color-option-yellow ${
                  colorsInclude("yellow") && "active"
                }`}
              ></div>
              <div
                onClick={() => handleColorChoice("orange")}
                className={`custom-border-all color-option color-option-orange ${
                  colorsInclude("orange") && "active"
                }`}
              ></div>
              <div
                onClick={() => handleColorChoice("purple")}
                className={`custom-border-all color-option color-option-purple ${
                  colorsInclude("purple") && "active"
                }`}
              ></div>
              <div
                onClick={() => handleColorChoice("brown")}
                className={`custom-border-all color-option color-option-brown ${
                  colorsInclude("brown") && "active"
                }`}
              ></div>
            </div>
          </div>
          <div className="col-lg-5"></div>
          <div className="col-lg-7 ps-4 ps-md-5 p-4 custom-border-bottom">
            <h5>Cene proizvoda</h5>
            <div className="py-3">
              <p>Maloprodajna:</p>
              <input
                onChange={(e) => setRetailPrice(e.target.value)}
                value={retailPrice}
                type="number"
                className="add-product-input-dimension mt-2"
              />
            </div>
            <div className="pb-3">
              <p>Veleprodajna:</p>
              <input
                onChange={(e) => setWholesalePricePrice(e.target.value)}
                value={wholesalePrice}
                type="number"
                className="add-product-input-dimension mt-2"
              />
            </div>
          </div>
          <div className="col-lg-5"></div>
          <div className="col-lg-7 ps-4 ps-md-5 p-4 custom-border-bottom">
            <h5>Slike proizvoda</h5>
            <div className="pb-4 pt-3">
              <p>Thumbnail:</p>
              <input
                accept="image/*"
                onChange={(e) => setThumb(e.target.files[0])}
                type="file"
                className="mt-3"
              />
            </div>
            <div className="pb-4">
              <p>Izgled u prostoru:</p>
              <input
                accept="image/*"
                onChange={(e) => setCover(e.target.files[0])}
                type="file"
                className="mt-3"
              />
            </div>
            <div className="pb-3">
              <p>Dodatne slike (galerija):</p>
              <div className="pt-3">
                <div
                  className="border rounded"
                  style={{
                    borderRadius: "10px",
                    border: "2px dashed black",
                    padding: "145px 0px",
                  }}
                  {...getRootProps({ className: "dropzone" })}
                >
                  <input {...getInputProps()} />
                  <p className="w-100 text-center">Prevucite slike ovde</p>
                  {thumbs}
                </div>
                {/* <aside className="mt-3"></aside> */}
              </div>
            </div>
          </div>
          <div className="col-lg-5"></div>
          <div className="col-lg-7 ps-4 ps-md-5 p-4 custom-border-bottom">
            <h5>Opis proizvoda</h5>
            <textarea
              onChange={(e) => setDesc(e.target.value)}
              value={desc}
              className="mt-3 custom-border-all bg-transparent w-100 p-3"
              rows={10}
            />
          </div>
          <div className="col-lg-5"></div>
          <div className="col-lg-7 ps-4 ps-md-5 p-4 custom-border-bottom">
            <h5>Kombinacije</h5>
            <div className="py-3">
              <p>
                Izaberite proizvode za odgovarajuće kombinacije selektovanjem
                odredjenog proizvoda u listi:
              </p>
            </div>
            {tilesLoading ? (
              "Ucitavanje..."
            ) : (
              <div className="rounded mt-3 combinations-cont custom-border-all">
                {tiles
                  ?.filter((n) => n.published === true)
                  .map((n) => (
                    <div
                    key={"tile" + n?.id}
                      onClick={() => handleCombinations(n?.id)}
                      className={`${
                        combinationsInclude(n?.id) && "active-combination"
                      } combination p-3 w-100 custom-border-bottom`}
                    >
                      {n?.name} {n?.category?.name}
                    </div>
                  ))}
              </div>
            )}
          </div>
          <div className="col-lg-5"></div>
          {errorMessage && (
            <div className="col-12 text-danger p-4 pt-5 px-4 px-md-5 d-flex align-items-center justify-content-start gap-2">
              <h5>{errorMessage}</h5>
            </div>
          )}
          <div className="col-lg-7 mb-5 p-4 px-4 px-md-5 d-flex align-items-center justify-content-start gap-2">
            <button
              disabled={createLoading}
              className="btn px-5 py-2 btn-success"
            >
              {createLoading ? "Procesiranje..." : "Potvrdi"}
            </button>
            <button
              disabled={createLoading}
              className="btn px-5 py-2 btn-danger"
              onClick={resetFormHandler}
            >
              Poništi
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdminPanelAddProduct;

import React from "react";
import Newsletter from "../components/Newsletter";
import { motion } from "framer-motion";
import PremiumTiles from "../components/PremiumTiles";

const Home = () => {
  const pdfPath = process.env.PUBLIC_URL + '/pdfs/AM-Katalog.pdf';

  return (
    <div>
      <div className="catalog-container px-3 px-md-5 d-flex align-items-center">
        <a href={pdfPath} download="AM-Katalog.pdf" className="">
          Preuzmite katalog
        </a>
      </div>
      <PremiumTiles />
      <div className="py-0 custom-container-md custom-border-bottom custom-border-top">
        <div className="row g-0">
          <div className="col-lg-5 py-5 pe-md-5">
            <motion.div
              initial={{ opacity: 0, x: -200 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.75 }}
              className="py-3 pe-3 custom-border-bottom"
            >
              <h3 className="pb-3 px-3 px-md-0 pt-0 pt-md-3">
                Kvalitetno i moderno
              </h3>
              <p className="px-3 px-md-0">
                Keramičke pločice koje nude su izrađene od najkvalitetnijih
                materijala, a njihova proizvodnja prati stroge standarde
                kvalitete kako bi se osigurala izdržljivost, otpornost na
                habanje i dugotrajnost.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: -200 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.75 }}
              className="py-3 pe-3 custom-border-bottom"
            >
              <h3 className="pb-3 px-3 px-md-0 pt-3">Široki asortiman</h3>
              <p className="px-3 px-md-0">
                Asortiman obuhvata širok spektar boja, uzoraka i tekstura
                pločica, što omogućuje kupcima da pronađu savršen proizvod za
                svoj prostor. Bez obzira tražite li modernu, minimalističku,
                rustikalnu ili luksuznu estetiku, ova firma ima proizvode koji
                će zadovoljiti vaše zahteve.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: -200 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.75 }}
              className="py-3 pe-3"
            >
              <h3 className="pb-3 px-3 px-md-0 pt-3">B2B opcije</h3>
              <p className="px-3 px-md-0">
                Ističemo se svojom visokom razinom poslovne saradnje s drugim
                firmama (B2B). Njihova stručnost i iskustvo u proizvodnji i
                distribuciji keramičkih pločica pružaju pouzdanog i kvalitetnog
                partnera za druge tvrtke u građevinskoj, dizajnerskoj i
                arhitektonskoj industriji.
              </p>
            </motion.div>
          </div>
          <div className="col-lg-7 d-flex justify-content-center align-items-center pb-5 pb-md-0 mb-2 mb-md-0">
            <div className="container">
              <div className="top-main"></div>
              <div className="top-secondary"></div>
              <div className="top-ternary-1"></div>
              <div className="top-ternary-2"></div>
              <div className="bottom-main"></div>
              <div className="bottom-ternary-2 min-height-box"></div>
              <div className="bottom-ternary-1"></div>
              <div className="bottom-secondary"></div>
            </div>
          </div>
        </div>
        <Newsletter />
      </div>
    </div>
  );
};

export default Home;

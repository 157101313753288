import React, { useState, useEffect } from "react";
import { FiMenu } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/am2title.png";
import { IoCartOutline } from "react-icons/io5";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import cartItemsCount from "../../store/selectors/cart/cartItemsCount";
import authUser from "../../store/selectors/auth/authUser";
import { authActions } from "../../store/slices/authSlice";

function Navbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //* Component state
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();

  //* Global state
  const cartItems = useSelector(cartItemsCount);
  const user = useSelector(authUser);

  const toggleNav = () => {
    document.body.style.overflow = isOpen ? "visible" : "hidden";
    setIsOpen((s) => !s);
  };

  const checkWindowSize = () => {
    if (window.innerWidth >= 1024) setIsOpen(false);
  };

  const logoutHandler = () => {
    dispatch(authActions.logout());
    navigate("/login");
  };

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  useEffect(() => {
    document.body.style.overflow = "visible";

    setIsOpen(false);
  }, [pathname]);

  useEffect(() => {
    window.addEventListener("resize", checkWindowSize);
    checkWindowSize();

    return () => {
      window.removeEventListener("resize", checkWindowSize);
    };
  }, []);

  return (
    <>
      <div className="top-nav">
        <motion.p
          initial={{ x: -400, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.75 }}
        >
          Pretraga širokog asortimana visokokvalitetnih proizvoda
        </motion.p>
      </div>
      <div className="app-navbar">
        <div className="app-nav-logo">
          <img src={logo} alt="AM" />
        </div>
        <div
          className={`toggle-container ${isOpen ? "open" : ""}`}
          onClick={toggleNav}
        >
          {!isOpen ? <FiMenu /> : <IoClose />}
        </div>
        <nav className={`app-navbar-links ${isOpen ? "open" : ""}`}>
          <Link className="app-nav-link-cont" to="/home">
            <div className="app-nav-link">Početna</div>
          </Link>
          <Link className="app-nav-link-cont" to="/products">
            <div className="app-nav-link">Proizvodi</div>
          </Link>
          <Link className="app-nav-link-cont" to="/locations">
            <div className="app-nav-link">Saloni</div>
          </Link>
          <Link className="app-nav-link-cont" to="/contact">
            <div className="app-nav-link">Kontakt</div>
          </Link>
          {user?.role === "Admin" ? (
          // {user ? (
            <Link className="app-nav-link-cont" to="/admin-panel">
              <div className="app-nav-link">Administracija</div>
            </Link>
          ) : (
            ""
          )}
          {user ? (
              <div className="app-nav-link small user-role text-capitalize text-muted me-4 ms-lg-auto">
                {/* <IoCartOutline />
                {cartItems > 0 && (
                  <div className="d-flex justify-content-center align-items-center cart-count bg-danger text-white">
                    <span>{cartItems}</span>
                  </div>
                )} */}
                Uloga: {user.role === "User" ? 'Korisnik' : 'Admin'}
              </div>
          ) : (
            ""
          )}
          {user ? (
            <Link className="app-nav-link-cont cart" to="/cart">
              <div className="app-nav-link">
                <IoCartOutline />
                {cartItems > 0 && (
                  <div className="d-flex justify-content-center align-items-center cart-count bg-danger text-white">
                    <span>{cartItems}</span>
                  </div>
                )}
              </div>
            </Link>
          ) : (
            ""
          )}
          {user ? (
            <button
              onClick={logoutHandler}
              className="app-nav-link-cont btn btn-transparent bg-transparent"
            >
              <div className="app-nav-link">Odjavi se</div>
            </button>
          ) : (
            <Link className="app-nav-link-cont ms-lg-auto" to="/login">
              <div className="app-nav-link">Prijavi se</div>
            </Link>
          )}
        </nav>
      </div>
    </>
  );
}

export default Navbar;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import cartService from "../../services/cartService";

const cartItemsFromStorage = JSON.parse(
  localStorage.getItem("cartItems") || "[]"
);

const initialState = {
  cartItems: cartItemsFromStorage,
  //! API call state
  status: "iddle",
  error: null,
  isLoading: false,
};

export const confirmCartAsync = createAsyncThunk(
  "cart/confirmCartAsync",
  async (payload, thunkAPI) => {
    try {
      return await cartService.confirmCart(payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
    },
    resetCart: (state) => {
      state.cartItems = [];
      localStorage.removeItem("cartItems");
    },
    addToCart: (state, action) => {
      const itemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );

      if (itemIndex === -1) {
        state.cartItems.push({ ...action.payload });
      } else {
        state.cartItems[itemIndex].amount = action.payload.amount;
      }

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    removeFromCart: (state, action) => {
      state.cartItems = state.cartItems.filter((n) => n.id !== action.payload);
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    increaseItemAmount: (state, action) => {
      state.cartItems = state.cartItems.map((n) =>
        n.id === action.payload ? { ...n, amount: n.amount + 1 } : n
      );
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    decreaseItemAmount: (state, action) => {
      state.cartItems = state.cartItems.map((n) =>
        n.id === action.payload ? { ...n, amount: n.amount - 1 } : n
      );
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    emptyCart: (state) => {
      state.cartItems = [];
      localStorage.removeItem("cartItems");
    },
  },
  extraReducers: (builder) => {
    //! Post subscription
    builder.addCase(confirmCartAsync.pending, (state) => {
      state.status = "idle";
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(confirmCartAsync.fulfilled, (state) => {
      state.status = "confirmCartAsyncSuccess";
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(confirmCartAsync.rejected, (state, action) => {
      state.status = "idle";
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const cartActions = cartSlice.actions;
export const cartReducers = cartSlice.reducer;

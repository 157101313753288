import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import tileService from "../../services/tileService";

const initialState = {
  status: "idle",
  premiumTiles: null,
  isLoading: false,
  error: null,
};

export const getAllPremiumTilesAsync = createAsyncThunk(
  "premiumTiles/getAllPremiumTilesAsync",
  async (payload, thunkAPI) => {
    try {
      return await tileService.getAllPremiumTiles(payload);
    } catch (e) {
      return thunkAPI.rejectWithValue({ error: "Greška..." });
    }
  }
);

export const premiumTilesSlice = createSlice({
  name: "premiumTiles",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    // Get All
    builder.addCase(getAllPremiumTilesAsync.pending, (state) => {
      state.status = "idle";
      state.isLoading = true;
      state.premiumTiles = null;
      state.error = null;
    });
    builder.addCase(getAllPremiumTilesAsync.fulfilled, (state, action) => {
      state.status = "getPremiumTilesSuccess";
      state.isLoading = false;
      state.premiumTiles = action.payload;
      state.error = null;
    });
    builder.addCase(getAllPremiumTilesAsync.rejected, (state, action) => {
      state.status = "idle";
      state.isLoading = false;
      state.premiumTiles = null;
      state.error = action.payload.error;
    });
  },
});

export const premiumTileActions = premiumTilesSlice.actions;
export const premiumTileReducers = premiumTilesSlice.reducer;

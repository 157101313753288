import React from "react";
import { Outlet } from "react-router-dom";
import AdminContainer from "../components/AdminContainer";
import ProtectedRoute from "../components/route/ProtectedRoute";

const AdminPanel = () => {
  return (
    <AdminContainer>
      <div className="custom-bg-light">
        <ProtectedRoute linkToNavigate={"/login"}>
          <Outlet />
        </ProtectedRoute>
      </div>
    </AdminContainer>
  );
};

export default AdminPanel;

import React from "react";

const NotFound = () => {
  return (
    <div className="py-5 my-5 px-3 px-md-5">
      <h1 className="text-danger pt-5">Greška 404!</h1>
      <h5 className="pb-5">Stranica nije pronađena.</h5>
    </div>
  );
};

export default NotFound;

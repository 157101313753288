import { API_URL } from "../config/apiUrl";
import CustomAxiosInsance from "../middleware/axios/connectionErrorMiddleware";

const api = CustomAxiosInsance();
api.defaults.baseURL = API_URL;

const methods = {
  getAllTiles: (payload) => {
    let query = "tiles?";

    if (payload?.ppsmw?.min) query += `minPpsmw=${+payload.ppsmw.min}&`;
    if (payload?.ppsmw?.max) query += `maxPpsmw=${payload.ppsmw.max}&`;
    if (payload?.ppsmr?.min) query += `minPpsmr=${+payload.ppsmr.min}&`;
    if (payload?.ppsmr?.max) query += `maxPpsmr=${payload.ppsmr.max}&`;
    if (payload?.length?.min) query += `minLength=${+payload.length.min}&`;
    if (payload?.length?.max) query += `maxLength=${payload.length.max}&`;
    if (payload?.width?.min) query += `minWidth=${payload.width.min}&`;
    if (payload?.width?.max) query += `maxWidth=${payload.width.max}&`;
    if (payload?.height?.min) query += `minHeight=${payload.height.min}&`;
    if (payload?.height?.max) query += `maxHeight=${payload.height.max}&`;
    if (payload?.pageNumber) query += `pageNumber=${payload.pageNumber}&`;
    if (payload?.colors?.length > 0)
      payload.colors.forEach((color) => (query += `colors=${color}&`));
    if (payload?.selectedCategories?.length > 0)
      payload.selectedCategories.forEach(
        (category) => (query += `categories=${category}&`)
      );

    //! Page size needed to get 4 similar tile ads
    if (payload?.pageSize) query += `pageSize=${payload.pageSize}&`;

    return api.get(query).then((response) => response.data);
  },
  getAllPremiumTiles: () =>
    api.get("tiles/premium-tiles").then((response) => response.data),
  getSingleTile: (payload) =>
    api.get("tiles/" + payload).then((response) => response.data),
  getCombinations: (payload) =>
    api.get("tiles/combinations/" + payload).then((response) => response.data),
  getAllAdminTiles: () =>
    api.get("tiles/admin-all").then((response) => response.data),
  postTile: (payload) =>
    api.post("tiles", payload).then((response) => response.data),
  deleteTile: (payload) =>
    api.delete("tiles/" + payload).then((res) => res.data),
  updatePublishing: (payload) =>
    api.put("tiles/soft-delete/" + payload).then((res) => res.data),
  updatePremium: (payload) =>
    api.put("tiles/premium/" + payload).then((res) => res.data),
  updatePrice: (payload) =>
    api
      .put("tiles/prices/" + payload.id, payload.prices)
      .then((res) => res.data),
};

export default methods;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import newsletterService from "../../services/newsletterService";

const initialState = {
  status: "idle",
  isLoading: false,
  error: null,
};

export const createSubscriptionAsync = createAsyncThunk(
  "newsletter/createSubscriptionAsync",
  async (payload, thunkAPI) => {
    try {
      return await newsletterService.createSubscription(payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const newsletterSlice = createSlice({
  name: "newsletter",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    //! Post subscription
    builder.addCase(createSubscriptionAsync.pending, (state) => {
      state.status = "idle";
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createSubscriptionAsync.fulfilled, (state) => {
      state.status = "createSubscriptionSuccess";
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(createSubscriptionAsync.rejected, (state, action) => {
      state.status = "idle";
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const newsletterActions = newsletterSlice.actions;
export const newsletterReducers = newsletterSlice.reducer;

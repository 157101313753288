import axios from "axios";

const CustomAxiosInsance = () => {
  const axiosInstance = axios.create();

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      //! Check if the error is a network error
      if (!error.response) {
        //* Redirect to connection error page
        //!   cannot use useNavigate because it is then called outside of an Component, and Hooks are not able to do so
        window.location.href = "/connection-error";
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default CustomAxiosInsance;

import React from "react";
import { useLocation } from "react-router-dom";
import Footer from "../shared/Footer";
import Navbar from "../shared/Navbar";

const RouteHandler = ({ children }) => {
  const { pathname } = useLocation();

  return pathname.includes("/admin-panel") ? (
    <>{children}</>
  ) : (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  );
};

export default RouteHandler;

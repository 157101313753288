import React, { useEffect, useState } from "react";
import noPic from "../assets/images/noPic.png";
import { AiOutlineDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import cartItemsCount from "../store/selectors/cart/cartItemsCount";
import cartItemsList from "../store/selectors/cart/cartItemsList";
import { cartActions, confirmCartAsync } from "../store/slices/cartSlice";
import { Link } from "react-router-dom";
import { BiMinusCircle, BiPlusCircle } from "react-icons/bi";
import authUser from "../store/selectors/auth/authUser";
import { IMAGE_URL } from "../config/apiUrl";

const Cart = () => {
  const dispatch = useDispatch();

  //* Component state
  const [success, setSuccess] = useState(false);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);

  //* Global state
  const cartItemsNum = useSelector(cartItemsCount);
  const items = useSelector(cartItemsList);
  const user = useSelector(authUser);
  const { isLoading, status } = useSelector((s) => s.cart);

  const deleteHandler = (id) => {
    dispatch(cartActions.removeFromCart(id));
  };

  const emptyCartHandler = () => dispatch(cartActions.emptyCart());

  const handleCheckbox1Change = (e) => {
    setCheckbox1(e.target.checked);
    setCheckbox2(!e.target.checked);
  };

  const handleCheckbox2Change = (e) => {
    setCheckbox2(e.target.checked);
    setCheckbox1(!e.target.checked);
  };

  const submitHandler = () => {
    if (window.confirm("Da li ste sigurni?")) {
      let order = {
        items: items.map(
          ({ name, id, wholeSalePrice, retailPrice, amount }) => ({
            nameWithCategory: name,
            id,
            wholeSalePrice,
            retailPrice,
            amount,
          })
        ),
        userFirstName: user?.user?.firstName,
        userLastName: user?.user?.lastName,
        userMail: user?.user?.email,
        userPhone: user?.user?.phoneNumber,
        retail: checkbox1,
        total: getOrderPrice(),
      };

      dispatch(confirmCartAsync(order));
    }
  };

  const getOrderPrice = () => {
    let total = 0;
    items.forEach((item) => {
      total += checkbox2
        ? item.retailPrice * item.amount
        : item.wholeSalePrice * item.amount;
    });

    return total;
  };

  const increaseHandler = (id) => dispatch(cartActions.increaseItemAmount(id));
  const decreaseHandler = (id) => dispatch(cartActions.decreaseItemAmount(id));

  useEffect(() => {
    if (status === "confirmCartAsyncSuccess") {
      setSuccess(true);
      dispatch(cartActions.resetStatus());
      dispatch(cartActions.emptyCart());
    }
  }, [status]);

  return (
    <div className="custom-container-md">
      <div className="w-100 px-2 navigation-map-text py-3 custom-border-top custom-border-bottom filters-bar d-flex justify-content-between align-items-center">
        {"Početna > Korpa"}
      </div>
      {cartItemsNum === 0 ? (
        <div className="mh-50vh-header-map px-3 px-lg-0 d-flex justify-content-center align-items-start flex-column w-md-50 text-start">
          {success && (
            <h4 className="text-success">Uspešno slanje narudžbine!</h4>
          )}
          <h1 className="text-start">
            Poštovani, Vaša korpa je trenutno prazna!
          </h1>
          <Link to="/products" className="mb-5 mt-3 py-2 btn btn-dark">
            Nazad na sve proizvode
          </Link>
        </div>
      ) : (
        <div className="row g-0">
          <div className="col-lg-9 p-3 cart">
            <div className="mh-100vh-header-map ">
              <button
                onClick={emptyCartHandler}
                className="btn btn-light mb-3 py-2 px-4"
              >
                Ukloni sve <AiOutlineDelete />
              </button>
              {items.map((n) => (
                <div
                  key={n.id}
                  className="cart-item p-3 mb-3 custom-border-all"
                >
                  <div key={n.id} className="cart-item-inner">
                    {/* n.imageSrc -> to be finished after picture storage has been implemented */}
                    <img
                      src={n.imageSrc ? IMAGE_URL + n.imageSrc : noPic}
                      alt="cart-item-img"
                    />
                    <h5 className="m-0 cart-item-title">{n.name}</h5>
                    <button
                      disabled={n.amount === 1}
                      onClick={() => decreaseHandler(n.id)}
                      className="btn btn-transparent amount-btn ms-2"
                    >
                      <BiMinusCircle />
                    </button>
                    <h5 className="m-0 cart-item-amount d-flex align-items-center justify-content-center">
                      <b>
                        {n.amount} m<sup>2</sup>
                      </b>
                    </h5>
                    <button
                      onClick={() => increaseHandler(n.id)}
                      className="btn btn-transparent amount-btn me-3"
                    >
                      <BiPlusCircle />
                    </button>
                    <h5 className="m-0 cart-item-price text-center custom-bg-gray h-100 py-2">
                      Maloprodajna:<br></br>
                      {n.retailPrice} RSD
                    </h5>
                    <h5 className="m-0 cart-item-price text-center custom-bg-gray h-100 py-2">
                      Veleprodajna:<br></br>
                      {n.wholeSalePrice} RSD
                    </h5>
                    <button
                      onClick={() => deleteHandler(n.id)}
                      className="btn btn-dark text-light d-flex justify-content-center align-items-center cart-item-remove-btn"
                    >
                      <AiOutlineDelete />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {cartItemsNum !== 0 && (
            <div className="col-lg-3">
              <div className="bg-light w-100 p-4 d-flex flex-column align-items-start">
                <span className="pb-3">Naručujete kao:</span>
                <div>
                  <input
                    checked={checkbox1}
                    onChange={handleCheckbox1Change}
                    type="checkbox"
                  />{" "}
                  Pravno lice
                </div>
                <div>
                  <input
                    checked={checkbox2}
                    onChange={handleCheckbox2Change}
                    type="checkbox"
                  />{" "}
                  Fizičko lice
                </div>
                {(checkbox1 || checkbox2) && (
                  <span className="pt-3">
                    Ukupno: <b>{getOrderPrice()} RSD</b>
                  </span>
                )}
                <button
                  disabled={!checkbox1 && !checkbox2}
                  onClick={submitHandler}
                  className="btn btn-dark w-100 py-2 mt-5"
                >
                  {isLoading ? "Slanje..." : "Potvrdi"}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Cart;

import React from "react";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";

const Pagination = ({ total, itemsPerPage, current, nextFn, prevFn }) => {
  return (
    <div className="custom-container-md py-3 d-flex justify-content-center align-items-center">
      {current > 1 && (
        <button
          className="btn d-flex align-items-center gap-2"
          onClick={prevFn}
        >
          <IoArrowBack />
          Prethodno
        </button>
      )}
      <span className="px-5 fw-bolder">
        {total > 0 ? (current - 1) * itemsPerPage + 1 : 0} -{" "}
        {current * itemsPerPage < total ? current * itemsPerPage : total} od{" "}
        {total}
      </span>
      {current && current < (total / itemsPerPage) && (
        <button
          className="btn d-flex align-items-center gap-2"
          onClick={nextFn}
        >
          Sledeće
          <IoArrowForward />
        </button>
      )}
    </div>
  );
};

export default Pagination;

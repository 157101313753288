import { API_URL } from "../config/apiUrl";
import CustomAxiosInsance from "../middleware/axios/connectionErrorMiddleware";

const api = CustomAxiosInsance();
api.defaults.baseURL = API_URL;

const methods = {
  register: (payload) =>
    api.post("identity/register", payload).then((response) => {
      const responseData = response.data;
      api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${responseData.token}`;
      return responseData;
    }),
    login: (payload) =>
      api.post("identity/login", payload).then((response) => {
        const responseData = response.data;
        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${responseData.token}`;
        return responseData;
      }),
  resetPassword: (payload) =>
    api.put("identity/reset-password", payload).then((response) => response.data),
};

export default methods;

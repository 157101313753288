import React from "react";
import { FiInstagram } from "react-icons/fi";
// import { FaFacebook } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="custom-container-md custom-bg-gray">
        <div className="row g-0 px-3 px-md-5">
          <div className="col-12 d-flex mb-4 justify-content-between align-items-center pt-4 pb-2 mb-1 text-center custom-border-bottom-dark">
            <h5 className="fw-bold">AM Keramika</h5>
          </div>
          <div className="col-lg-6 pe-5 d-flex flex-column justify-content-start align-items-start">
            <h6 className="mt-3 mt-md-0 custom-border-bottom-dark w-75 py-2 fw-bold">
              O nama
            </h6>
            <div className="py-3 pe-1 pe-md-5 mt-3 txt-sm">
            AM Keramika je mlada firma koja je osnovana 2023. godine sa ciljem da postane vodeći dobavljač keramičkih pločica na tržištu. Svojom stručnošću, kvalitetom proizvoda i uslugama, te brzom i pouzdanom dostavom, AM Keramika se uspešno plasirala na tržištu i stekla brojne zadovoljne kupce širom Srbije.

Naša glavna delatnost je prodaja keramičkih pločica, a u ponudi imamo širok asortiman proizvoda od različitih proizvođača. Odabrali smo najbolje proizvode kako bismo osigurali najveći kvalitet za naše kupce. Takođe, imamo i stručni tim koji će Vam rado pomoći pri odabiru prave keramičke pločice za Vaš dom, te Vam dati savete i preporuke kako biste postigli željeni izgled.


            </div>
          </div>
          <div className="col-lg-2 d-flex flex-column justify-content-start align-items-start">
            <h6 className="custom-border-bottom-dark w-75 py-2 fw-bold">
              Mapa sajta
            </h6>
            <div className="site-map p-1"></div>
            <Link className="mt-3 txt-sm site-map-link" to="/home">
              Početna
            </Link>
            <Link className="site-map-link txt-sm" to="/products">
              Proizvodi
            </Link>
            <Link className="site-map-link txt-sm" to="/locations">
              Saloni
            </Link>
            <Link className="site-map-link txt-sm" to="/contact">
              Kontakt
            </Link>
          </div>
          <div className="col-lg-4 d-flex flex-column justify-content-start align-items-start">
            <h6 className="custom-border-bottom-dark w-75 py-2 mt-4 mt-lg-0 fw-bold">
              Tehnička podrška
            </h6>
            <div className="py-2 mt-4 pe-2 txt-sm">
              <b>E-mail podrška:</b>
              <br></br>
              Za sva pitanja, predloge ili pomoć, možete da nam se obratite i
              putem email-a na{" "}
              <span className="fw-bold d-block py-2">
                salonkeramikeam@gmail.com
              </span>
              u bilo koje vreme, a odgovore ćete dobiti u najkraćem roku.
              <br></br>
              <br></br>
              <b>Telefonska podrška:</b>
              <br></br>
              Možete nas kontaktirati i putem telefona{" "}
              <span className="fw-bold d-block py-2">+381 63 652 222</span>
              <span className="fw-bold d-block pb-2">+381 69 123 1111</span> radnim
              danima u vremenu od 09:00 do 17:00. Sa zadovoljstvom ćemo
              odgovoriti na sva Vaša pitanja.
            </div>
          </div>
          <div className="col-12 mt-4 mt-lg-3 d-flex justify-content-between align-items-center py-3 text-center custom-border-top-dark">
            <span className="fw-bold">Zapratite nas na:</span>
            <div className="d-flex gap-4 justify-content-center alig-items-center">
                {/* <FaFacebook className="social-link" /> */}
              <Link className="text-decoration-none text-dark" to="https://instagram.com/amkeramika?igshid=ZDdkNTZiNTM=">
              <FiInstagram className="social-link" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="custom-container-md text-center text-md-start custom-bg-dark text-white footer-bottom py-3  custom-border-top">
        <p className="ps-0 ps-md-5">
          Copyright Ⓒ 2023 AM Keramika<br></br>Sva prava zadržana
        </p>
      </div>
    </>
  );
};

export default Footer;

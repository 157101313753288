import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions, registerAsync } from "../store/slices/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { validateEmail } from "../helpers/validations";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //* Component state
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [subscribed, setSub] = useState(false);
  const [firstNameMessage, setFirstNameMessage] = useState(null);
  const [lastNameMessage, setLastNameMessage] = useState(null);
  const [phoneNumberMessage, setPhoneNumberMessage] = useState("");
  const [emailMessage, setEmailMessage] = useState(null);
  const [passwordMessage, setPasswordMessage] = useState(null);
  const [passwordConfirmMessage, setPasswordConfirmMessage] = useState(null);
  const [success, setSuccess] = useState(false);
  const [countdown, setCountdown] = useState(5);

  //* Global state
  const { status, user, isLoading, registerError } = useSelector(
    (state) => state.auth
  );

  const onSubmitHandler = (e) => {
    e.preventDefault();

    setFirstNameMessage(null);
    setLastNameMessage(null);
    setEmailMessage(null);
    setPasswordMessage(null);
    setPasswordConfirmMessage(null);
    setPhoneNumberMessage(null);

    if (firstName.trim().length === 0) {
      setFirstNameMessage("Polje za ime ne sme biti prazno");
      return;
    }

    if (lastName.trim().length === 0) {
      setLastNameMessage("Polje za prezime ne sme biti prazno");
      return;
    }

    if (email.trim().length === 0 || !validateEmail(email)) {
      setEmailMessage("Neispravan email");
      return;
    }

    if (password.trim().length === 0) {
      setPasswordMessage("Polje za lozinku ne sme biti prazno");
      return;
    }

    if (passwordConfirm.trim().length === 0) {
      setPasswordConfirmMessage("Polje za potvrdu lozinke ne sme biti prazno");
      return;
    }

    if (
      passwordConfirm.trim().toLowerCase() !== password.trim().toLowerCase()
    ) {
      setPasswordConfirmMessage("Šifre moraju biti iste");
      return;
    }

    if (phoneNumber.trim().length === 0) {
      setPhoneNumberMessage("Polje za broj telefona ne sme biti prazno");
      return;
    }

    dispatch(
      registerAsync({
        firstName,
        lastName,
        email,
        password,
        role: "User",
        phoneNumber,
        newsletterSubscribed: subscribed,
      })
    );
  };

  //! Reseted the error because when switching from login
  //! to register the error does not reset
  //! which is a bug that is fixed temporary
  useEffect(() => {
    dispatch(authActions.resetErrors());
  }, [dispatch]);

  useEffect(() => {
    if (status.includes("authSuccess") && user) {
      setSuccess(true);
    }
  }, [status, user, dispatch, navigate]);

  useEffect(() => {
    if (success) {
      const intervalId = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      setTimeout(() => {
        clearInterval(intervalId);
        dispatch(authActions.resetStatus());
        localStorage.setItem("user", JSON.stringify(user));
        navigate("/home");
      }, 5000);
    }
  }, [success]);

  return (
    <div className="custom-container-md pb-5">
      <div className="w-100 px-2 navigation-map-text py-3 custom-border-top custom-border-bottom filters-bar d-flex justify-content-between align-items-center">
        {"Registracija"}
      </div>
      <div className="row g-0 py-2 py-lg-5 custom-border-top custom-border-bottom">
        <div className="col-lg-3"></div>
        <div className="col-lg-6 p-4 d-flex justify-content-center align-items-center flex-column">
          <form className="register-form w-md-50" onSubmit={onSubmitHandler}>
            <label>Ime</label>
            <input
              className="py-2 px-3 w-100"
              type="text"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
            />
            <br />
            {firstNameMessage && (
              <p className="text-danger">{firstNameMessage}</p>
            )}
            <br></br>
            {/* <br></br> */}
            <label>Prezime</label>
            <input
              className="py-2 px-3 w-100"
              type="text"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
            <br />
            {lastNameMessage && (
              <p className="text-danger">{lastNameMessage}</p>
            )}
            <br></br>
            {/* <br></br> */}
            <label>E-mail</label>
            <input
              className="py-2 px-3 w-100"
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <br />
            {emailMessage && <p className="text-danger">{emailMessage}</p>}
            <br></br>
            {/* <br></br> */}
            <label>Šifra</label>
            <input
              className="py-2 px-3 w-100"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <br />
            {passwordMessage && (
              <p className="text-danger">{passwordMessage}</p>
            )}
            <br></br>
            {/* <br></br> */}
            <label>Potvrdite šifru</label>
            <input
              className="py-2 px-3 w-100"
              type="password"
              onChange={(e) => setPasswordConfirm(e.target.value)}
              value={passwordConfirm}
            />
            <br />
            {passwordConfirmMessage && (
              <p className="text-danger">{passwordConfirmMessage}</p>
            )}
            <br></br>
            {/* <br></br> */}
            <label>Broj telefona</label>
            <input
              className="py-2 px-3 w-100"
              type="text"
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber}
            />
            <br />
            {phoneNumberMessage && (
              <p className="text-danger">{phoneNumberMessage}</p>
            )}
            <br></br>
            {/* <br></br> */}
            <input
              className="py-2 me-2"
              type="checkbox"
              onChange={(e) => setSub(e.target.checked)}
              value={subscribed}
            />
            <label>Newsletter subskripcija</label>
            <br></br>
            <br></br>
            {registerError && <p>{registerError.Message}</p>}
            <input
              disabled={success}
              className="btn mt-2 btn-dark w-100 py-3 px-3"
              type="submit"
              value={isLoading ? "Verifikacija..." : "Registruj se"}
            />
          </form>
          {success && (
            <div className="pt-5">
              <p>
                Preostalo vreme procesiranja: <b>{countdown} s</b>
              </p>
            </div>
          )}
          <div className="mt-5 mb-5 text-center login-to-register custom-border-top pt-5">
            Imate već nalog?
            <Link
              to="/login"
              className="d-block text-dark text-decoration-underline"
            >
              Prijavite se sada
            </Link>
          </div>
        </div>
        <div className="col-lg-3"></div>
      </div>
    </div>
  );
};

export default Register;

import React from "react";
import { VscEdit } from "react-icons/vsc";
import { AiFillStar, AiOutlineDelete, AiOutlineStar } from "react-icons/ai";
import { MdSearch } from "react-icons/md";
import { Link } from "react-router-dom";
import noPic from "../assets/images/noPic.png";
import { IMAGE_URL } from "../config/apiUrl";
import { useDispatch } from "react-redux";
import {
  deleteTileAsync,
  updatePremiumTileAsync,
  updatePublishedTileAsync,
} from "../store/slices/adminTilesSlice";

const ProductAsItem = ({ product }) => {
  const dispatch = useDispatch();

  const deleteHandler = (id) => {
    if (window.confirm("Da li ste sigurni?")) {
      dispatch(deleteTileAsync(id));
    }
  };

  const togglePublished = (id) => {
    if (window.confirm("Da li ste sigurni?")) {
      dispatch(updatePublishedTileAsync(id));
    }
  };

  const premiumHandler = (id) => {
    if (window.confirm("Da li ste sigurni?")) {
      dispatch(updatePremiumTileAsync(id));
    }
  };

  return (
    <div>
      <div
        className={`bg-white product-asitem d-flex justify-content-start align-items-center gap-5 py-2 px-2`}
      >
        <img
          className="product-asitem-image"
          src={
            product?.mainImageUrl ? IMAGE_URL + product?.mainImageUrl : noPic
          }
        />
        <div className="product-asitem-title">
          {product?.name} {product?.category.name}
        </div>
        <div className="d-flex ms-auto align-items-center">
          <button
            disabled={product?.isPremium}
            onClick={() => premiumHandler(product?.id)}
            className={`btn rounded-circle premium-btn ${
              product?.isPremium ? "text-warning" : "text-black"
            } me-4`}
          >
            {product?.isPremium ? <AiFillStar /> : <AiOutlineStar />}
          </button>
          <button
          disabled={product?.isPremium}
            onClick={() => togglePublished(product?.id)}
            className={`btn published-btn ${
              product?.published ? "btn-success" : "btn-danger"
            } custom-border-all me-4`}
          >
            {product?.published ? "Objavljeno" : "Skriveno"}
          </button>
          <Link
            to={`/admin-panel/edit-product/${product?.id}`}
            className="btn product-asitem-icon-btn"
          >
            <VscEdit />
          </Link>
          <button
            onClick={() => deleteHandler(product?.id)}
            disabled={product?.isPremium}
            className="btn border-0 product-asitem-icon-btn text-danger"
          >
            <AiOutlineDelete className="" />
          </button>
          {product?.published ? (
            <Link
              to={`/products/${product?.id}`}
              className="btn product-asitem-icon-btn"
            >
              <MdSearch className="" />
            </Link>
          ) : (
            <button
              disabled={true}
              className="btn border-0 product-asitem-icon-btn"
            >
              <MdSearch className="" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductAsItem;
